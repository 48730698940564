
const generateMonthTags = (offset = 0, numMonths = 12) => {
  const currentDate = new Date();
  currentDate.setDate(1);
  const months = [];

  for (let i = 0; i < numMonths; i += 1) {
    const date = new Date(currentDate);
    date.setMonth(currentDate.getMonth() - offset - i);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    months.push(`${year}_${month}`);
  }

  return months;
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const duplicateCheck = (currentJsons, addJsons) => {
  const newMap = {};
  const duplicateMap = {};
  addJsons.forEach((aJson) => {
    const existJson = currentJsons.find((cJson) => {
      return aJson.invoice_number === cJson.invoice_number
    });
    if (existJson && existJson.invoice_number) {
      if (!duplicateMap[existJson.invoice_number]) {
        duplicateMap[existJson.invoice_number] = {
          originJson: existJson,
          updateJsons: []
        };
      }
      duplicateMap[existJson.invoice_number].updateJsons.push(aJson);
    } else {
      newMap[aJson.invoice_number] = aJson;
      // newJsons.push(aJson);
    }
  });
  const newJsons = Object.keys(newMap).map((nKey) => {
    return newMap[nKey];
  });
  const duplicateJsons = Object.keys(duplicateMap).map((dKey) => {
    const attKeys = Object.keys(duplicateMap[dKey].originJson);
    const json = JSON.parse(JSON.stringify(duplicateMap[dKey].originJson));
    duplicateMap[dKey].updateJsons.forEach((uJson) => {
      attKeys.forEach((aKey) => {
        json[aKey] = uJson[aKey] || json[aKey];
      })
    });
    return {
      originJson: duplicateMap[dKey].originJson,
      updateJson: json
    }
  });
  return {
    newJsons,
    duplicateJsons
  };
};

const dataValidation = (isNew, form, attributes) => {
  if (!form.invoice_number) {
    return {
      status: false,
      message: 'Invoice number is required',
      messageId: 'invoice_number_required'
    };
  }
  // TODO: Validation
  return {
    status: true,
    message: 'Success',
    messageId: 'success'
  };
};

export {
  generateMonthTags,
  getComparator,
  stableSort,
  duplicateCheck,
  dataValidation
};
