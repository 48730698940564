import { CONSTANTS } from "./constants";

const AUDIT_RESULTS_INIT_INFO = {
  sheetRowSuccessResults: [],
  sheetRowWarnResults: [],
  unmatchedRules: []
};

export default (state = AUDIT_RESULTS_INIT_INFO, action) => {
  switch (action.type) {
    case CONSTANTS.SET_AUDIT_RESULTS:
      const {
        sheetRowSuccessResults = state.sheetRowSuccessResults,
        sheetRowWarnResults = state.sheetRowWarnResults,
        unmatchedRules = state.unmatchedRules
      } = action.value;
      return {
        sheetRowSuccessResults,
        sheetRowWarnResults,
        unmatchedRules
      };
    default:
      return state;
  }
};
