import React from 'react';
import PropTypes from 'prop-types';
// material-ui
import FormControl from '@mui/material/FormControl';
// cust component
import DatePicker from './DatePicker';

/* ***********
  <DateRangeFilter
    enable={true}
    style={{}}
    title={''}
    leftLabel={''}
    rightLabel={''}
    leftValue={null}
    rightValue={null}
    onLeftChange={(event) => {
      const value = event.target.value;
    }}
    onRightChange={(event) => {
      const value = event.target.value;
    }}
  />
*********** */

const DateRangeFilter = (props) => {
  if (!props.enable) {
    return null;
  }
  const leftValue = props.leftValue ? new Date(props.leftValue) : null;
  const rightValue = props.rightValue ? new Date(props.rightValue) : null;
  return (
    <FormControl style={props.style}>
      <label>{props.title}</label>
      <div>
        <div style={{ float: 'left' }}>
          <DatePicker
            enable={true}
            label={props.leftLabel}
            value={leftValue}
            onChange={props.onLeftChange}
          />
        </div>
        <div style={{ float: 'left', margin: '5px 7px' }}>~</div>
        <div style={{ float: 'left' }}>
          <DatePicker
            enable={true}
            label={props.rightLabel}
            value={rightValue}
            onChange={props.onRightChange}
          />
        </div>
      </div>
    </FormControl>
  );
};

DateRangeFilter.propTypes = {
  enable: PropTypes.bool.isRequired,
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  leftLabel: PropTypes.string.isRequired,
  rightLabel: PropTypes.string.isRequired,
  leftValue: PropTypes.any,
  rightValue: PropTypes.any,
  onLeftChange: PropTypes.func.isRequired,
  onRightChange: PropTypes.func.isRequired
};

export default DateRangeFilter;
export {
  DateRangeFilter
};
