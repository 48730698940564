import { CONSTANTS } from "./constants";

type DEAL_LIST_TYPE = {
  [keyStr: string]: any, // row key value
  dataForm: string,
  owner_name: string,
  owner_email: string,
  owner_uid: string
};

const DEAL_LIST: Array<DEAL_LIST_TYPE> = [];

export default (state = DEAL_LIST, action) => {
  switch (action.type) {
    case CONSTANTS.SET_DEAL_LIST :
      return JSON.parse(JSON.stringify(action.value));

    default:
      return state;
  }
}
