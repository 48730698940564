import React from 'react';
import { Grid } from '@mui/material';

import { AccountDetails } from './components';

import viewCss from '../../assets/scss/view.module.scss';

const Account = () => {

  return (
    <div className={viewCss.Account}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <AccountDetails />
        </Grid>
      </Grid>
    </div>
  );
};

export default Account;
