import { CONSTANTS } from "./constants";

const initialStatus = {
  dealList: false,
  auditRows: false,
  sheetDataForm: false
};

export default (state = initialStatus, action) => {
  switch (action.type) {
    case CONSTANTS.DATA_INIT_STATUS :
      const updateStatus = {
        ...state,
        ...action.value
      };
      return updateStatus;
    default:
      return state;
  }
}
