import { CONSTANTS } from "./constants";

const AUDIT_ROWS_ORDER_INIT_INFO = {
  order: '', // asc, desc
  orderBy: ''
};

export default (state = AUDIT_ROWS_ORDER_INIT_INFO, action) => {
  switch (action.type) {
    case CONSTANTS.SET_AUDIT_ROWS_ORDER_INFO:
      const {
        order = state.order,
        orderBy = state.orderBy
      } = action.value;

      return { order, orderBy };
    default:
      return state;
  }
};
