import { CONSTANTS } from "./constants";

const INIT_AUDIT_ROWS_FILTER_INFO = {
  greedy: '',
  dateTags: []
};

export default (state = INIT_AUDIT_ROWS_FILTER_INFO, action) => {
  switch (action.type) {
    case CONSTANTS.SET_AUDIT_ROWS_FILTER_INFO :
      return action.value;
    default:
      return state;
  }
};
