import { combineReducers } from 'redux';
import account from './account';
import auth from './auth';
import dealList from './dealList';
import loading from './Loading';
import notifyRule from './notifyRule';
import orgMember from './orgMember';
// import filterStr from './filterStr';
// import statusFilter from './statusFilter';
// import updateRangeFilter from './updateRangeFilter';
// import productTypeFilter from './productTypeFilter';
// import progressFilter from './progressFilter';
// import estimatedClosingFilter from './estimatedClosingFilter';
import filterInfo from './filterInfo';
import auditFilterInfo from './auditFilterInfo';
import auditRowsFilterInfo from './auditRowsFilterInfo';
import order from './order';
import orderBy from './orderBy';
// import filterOwner from './filterOwner';
// import greedyFilterStr from './greedyFilterStr';
import sheetDataForm from './sheetDataForm';
import auditSheetDataForm from './auditSheetDataForm';
// import filterDataForm from './filterDataForm.js';
// import formInfos from './formInfos.js';
import screen from './screen.js';
import taskLog from './taskLog.js';
import taskLogPage from './taskLogPage.js'
import auditResults from './auditResults';
import auditDatas from './auditDatas';
import auditRowsOrderInfo from './auditRowsOrderInfo';
import auditOrderInfo from './auditOrderInfo';
import dataInitStatus from './dataInitStatus';

export default combineReducers({
  account,
  auth,
  dealList,
  loading,
  notifyRule,
  orgMember,
  // filterStr,
  // statusFilter,
  // updateRangeFilter,
  // productTypeFilter,
  // progressFilter,
  // estimatedClosingFilter,
  filterInfo,
  auditFilterInfo,
  auditRowsFilterInfo,
  order,
  orderBy,
  // filterOwner,
  // greedyFilterStr,
  sheetDataForm,
  auditSheetDataForm,
  // filterDataForm,
  // formInfos,
  screen,
  taskLog,
  taskLogPage,
  auditResults,
  auditDatas,
  auditRowsOrderInfo,
  auditOrderInfo,
  dataInitStatus
});
