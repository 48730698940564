import React from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";
import './assets/scss/index.scss';
import AppRoutes from './AppRoutes';
import envConfig from './configuration/env.json';

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const App = (props) => {
  return (
    <Router>
      <Auth0ProviderWithRedirectCallback
        domain={envConfig.domain}
        clientId={envConfig.clientId}
        authorizationParams={{
          redirect_uri: `${window.location.origin}/sign-in`,
          audience: envConfig.audience,
          scope: envConfig.scope
        }}
      >
        <AppRoutes />
      </Auth0ProviderWithRedirectCallback>
    </Router>
  );
};

export default App;
