import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
// material-ui
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
// cust component
import { SearchInputWithClear, PickSelect, MutiPickSelect, DatePicker, DateRangeFilter } from '../CustComponents';
import { ConstructionOutlined } from '@mui/icons-material';
// lib

const STR_FILTER_TYPES = ['strict', 'greedy', 'string'];
const SELECT_FILTER_TYPES = ['select'];
const MUTI_SELECT_FILTER_TYPES = ['muti_select'];
const DATE_RANGE_FILTER_TYPES = ['date_range'];
const DATE_FILTER_TYPES = ['date'];

const ACCORDION_STR = 'accordion';
const DIALOG_STR = 'dialog';

const FilterComponentLayout = (props) => {
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);

  const onFilterDialogOpen = () => {
    setFilterDialogOpen(true);
  };

  const onFilterDialogClose = () => {
    setFilterDialogOpen(false);
  };

  if (props.display === ACCORDION_STR) {
    const firstChild = props.children[0]; // 第一个子元素
    const remainingChildren = props.children.slice(1); // 剩余的子元素数组

    return (
      <div style={{ height: '69px' }} ref={props.filterRef}>
        <Accordion style={{ zIndex: 10, width: '340px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <span style={{ width: '70px', marginTop: '10px' }}>
              <FormattedMessage id='search'/>
            </span>
            {firstChild}
          </AccordionSummary>
          <AccordionDetails>
            {remainingChildren}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
  if (props.display === DIALOG_STR) {
    return (
      <>
        <Tooltip title={props.intl.formatMessage({ id: 'search' })} ref={props.filterRef}>
          <IconButton onClick={onFilterDialogOpen}>
            <SearchIcon fontSize='large' color='primary'/>
          </IconButton>
        </Tooltip>
        <Dialog open={filterDialogOpen} onClose={() => {}} fullScreen>
          <DialogTitle style={{ fontSize: '28px' }}>
            {props.intl.formatMessage({ id: 'search' })}
          </DialogTitle>
          <DialogContent>
            <div>
              {props.children}
            </div>
            <div style={{ textAlign: 'center', marginTop: '15px' }}>
              <Button style={{ width: '100%' }} variant='contained' onClick={onFilterDialogClose}>
                <FormattedMessage id='close' />
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
  return (
    <div style={{ maxWidth: 'calc(100vw - 350px)' }} ref={props.filterRef}>
      {props.children}
      <div style={{ clear: 'both' }}/>
    </div>
  );
};

/* ************************
const FILTER_INFOS = Array<{
  name: string,
  description: string,
  label: string,
  label_id: string,
  placeholder: Array<string>,
  placeholder_id: Array<string>,
  type: string, // data_range, string, number, date
  keys: Array<string>, // array for multi keys.
  lookup: Array<{ label: string, label_id: ?string, value: any }>,
  condition: string // equal more less or range,
}>
<FilterComponent
  display={''} // accordion, dialog, plain
  filterForm={filterForm}
  filterInfos={FILTER_INFOS}
  onFilterFormChange={(key, value) => {
    const obj = JSON.parse(JSON.stringify(filterForm));
    obj[key] = value;
    setFilterForm(obj);
  }}
/>
************************ */

const FilterComponent = (props) => {
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const filterRef = useRef(null);

  const fieldStyle = [DIALOG_STR, ACCORDION_STR].includes(props.display) ? {
    width: '100%'
  } : {
    float: 'left', minWidth: '150px', margin: '0px 10px', height: '63px'
  };

  useEffect(() => {
    const updateDimensions = () => {
      const { width, height } = filterRef.current.getBoundingClientRect();
      props.onFilterWHChange(width, height);
    };

    // 初始化時取得一次元件寬高
    updateDimensions();

    // 監聽視窗大小變化事件，並更新元件寬高
    window.addEventListener('resize', updateDimensions);

    // 移除事件監聽器以防止記憶體洩漏
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return (
    <FilterComponentLayout {...props} display={props.display} filterRef={filterRef}>
      {
        props.filterInfos.filter((filterInfo) => {
          return filterInfo.enable;
        }).map((filterInfo) => {
          if (SELECT_FILTER_TYPES.includes(filterInfo.type)) {
            const lookupOptions = props.filterInfo.lookup.map((lkObj) => {
              return {
                label: lkObj.label_id ? props.intl.formatMessage({ id: lkObj.label_id }) : lkObj.label,
                value: lkObj.value
              }
            });
            return (
              <PickSelect
                enable={true}
                style={fieldStyle}
                title={props.intl.formatMessage({ id: filterInfo.label_id })}
                value={props.filterForm[filterInfo.keys[0]] || ''}
                options={lookupOptions} // Array<{ label: string, value: any }>
                onChange={(event) => {
                  props.onFilterFormChange(filterInfo.keys[0], event.target.value);
                }}
              />
            );
          }
          if (MUTI_SELECT_FILTER_TYPES.includes(filterInfo.type)) {
            const lookupOptions = filterInfo.lookup.map((lkObj) => {
              return {
                label: lkObj.label_id ? props.intl.formatMessage({ id: lkObj.label_id }) : lkObj.label,
                value: lkObj.value
              }
            });
            return (
              <MutiPickSelect
                enable={true}
                style={fieldStyle}
                title={props.intl.formatMessage({ id: filterInfo.label_id })}
                value={props.filterForm[filterInfo.keys[0]] || []}
                options={lookupOptions} // Array<{ label: string, value: any }>
                onChange={(event) => {
                  props.onFilterFormChange(filterInfo.keys[0], event.target.value);
                }}
              />
            );
          }
          if (DATE_RANGE_FILTER_TYPES.includes(filterInfo.type)) {
            return (
              <DateRangeFilter
                enable={true}
                style={fieldStyle}
                title={props.intl.formatMessage({ id: filterInfo.label_id })}
                leftLabel={props.intl.formatMessage({ id: filterInfo.placeholder_id[0] })}
                rightLabel={props.intl.formatMessage({ id: filterInfo.placeholder_id[1] })}
                leftValue={props.filterForm[filterInfo.keys[0]]}
                rightValue={props.filterForm[filterInfo.keys[1]]}
                onLeftChange={(value) => {
                  props.onFilterFormChange(filterInfo.keys[0], value);
                }}
                onRightChange={(value) => {
                  props.onFilterFormChange(filterInfo.keys[1], value);
                }}
              />
            );
          }
          if (DATE_FILTER_TYPES.includes(filterInfo.type)) {
            return (
              <DatePicker
                enable={true}
                style={fieldStyle}
                label={props.intl.formatMessage({ id: filterInfo.label_id })}
                value={props.filterForm[filterInfo.keys[0]]}
                onDateChange={(event) => {
                  props.onFilterFormChange(filterInfo.keys[0], event.target.value);
                }}
              />
            );
          }
          // default: STR_FILTER_TYPES
          return (
            <SearchInputWithClear
              enable={true}
              style={fieldStyle}
              title={props.intl.formatMessage({ id: filterInfo.label_id })}
              placeholder={props.intl.formatMessage({ id: filterInfo.placeholder_id[0] })}
              value={props.filterForm[filterInfo.keys[0]]}
              onChange={(event) => {
                props.onFilterFormChange(filterInfo.keys[0], event.target.value);
              }}
            />
          );
        })
      }
    </FilterComponentLayout>
  );
};

FilterComponent.propTypes = {
  display: PropTypes.string.isRequired,
  filterForm: PropTypes.object.isRequired,
  filterInfos: PropTypes.array.isRequired,
  onFilterFormChange: PropTypes.func.isRequired
};

export default injectIntl(FilterComponent);
