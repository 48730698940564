import React from 'react';
import PropTypes from 'prop-types';
// material-ui
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';

const SearchInput = (props) => {
  const { onChange, style, ...rest } = props;

  return (
    <Paper
      {...rest}
      style={{
        borderRadius: '4px',
        alignItems: 'center',
        padding: '5px',
        display: 'flex',
        flexBasis: 420,
        ...style
      }}
    >
      <SearchIcon/>
      <Input
        style={{
          flexGrow: 1,
          fontSize: '14px',
          lineHeight: '16px',
          letterSpacing: '-0.05px'
        }}
        {...rest}
        disableUnderline
        onChange={onChange}
      />
    </Paper>
  );
};

SearchInput.propTypes = {
  style: PropTypes.object,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  endAdornment: PropTypes.object
};

/*
  <SearchInputWithClear
    enable={true}
    style={{}}
    title={''}
    placeholder={''}
    value={''}
    onChange={(event) => {
      const value = event.target.value;
    }}
  />
*/
const SearchInputWithClear = (props) => {
  if (!props.enable) {
    return null;
  }
  return (
    <Tooltip title={props.title} style={props.style}>
      <SearchInput
        value={props.value}
        placeholder={ props.placeholder}
        onChange={props.onChange}
        endAdornment={(
          <IconButton size='small' onClick={(e) => {
            props.onChange({ target: { value: '' } });
          }}>
            <ClearIcon />
          </IconButton>
        )}
      />
    </Tooltip>
  );
};

SearchInputWithClear.propTypes = {
  enable: PropTypes.bool,
  style: PropTypes.object,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SearchInputWithClear;

export {
  SearchInput,
  SearchInputWithClear
};
