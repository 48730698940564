import React, { useState, useEffect } from 'react';
import { injectIntl, FormattedMessage } from "react-intl";
import PropTypes from 'prop-types';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { confirmAlert } from 'react-confirm-alert';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { connect } from 'react-redux';
import Loader from './Loading.gif';

import { setAccountInfo, setLoading } from '../../../../actions'

import viewCss from '../../../../assets/scss/view.module.scss';

import 'react-confirm-alert/src/react-confirm-alert.css';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@mui/material';

import { httpRequest } from '../../../../lib';

const LANGUAGE_LIST = ['en', 'zh-CN', 'zh-TW'];

let originProfile = {};

const AccountDetails = props => {
  const { className, ...rest } = props;
  const [displayLang, setDisplayLang ] = useState('en');

  // const initProfile = () => {
  //   props.setLoading(true);

  //   httpRequest('GET', '/api/account', {}, props.auth.accessToken, (statusCode, body) => {
  //     if (!body.status) {
  //       return;
  //     }

  //     props.setLoading(false);

  //     originProfile = {...body.info};
  //     props.setAccountInfo({...body.info});
  //   });
  // };

  useEffect(() => {
    // initProfile();
    originProfile = { ...props.account };
    setDisplayLang(originProfile.language);
  }, [props.auth.accessToken]);

  const handleChange = (event) => {
    const newAccount = { ...props.account };
    newAccount[event.target.name] = event.target.value;
    props.setAccountInfo(newAccount);
  };

  const handleSaveProfile = (event) => {
    
    props.setLoading(true);
    let info = { ...props.account };
    if (displayLang !== info.language) {
      info.language = displayLang;
      props.setAccountInfo(info);
    }
    httpRequest('POST', '/api/account', { info }, props.auth.accessToken, (statusCode, body) => {
      props.setLoading(false);

      if (!body.status) {
        confirmAlert({
          title: 'Warning',
          message: 'Update Failed',
          buttons: [{ label: 'Yes', onClick: () => window.location.reload() }]
        });
        return;
      }
      originProfile = {...info};
      confirmAlert({
        title: 'Success',
        message: 'Success',
        buttons: [{ label: 'Yes' }]
      });
    });
  };

  return (
    <>
      { props.loading && (
      <div style={{zIndex: '10',position:'fixed', top:'0', left: '0', backgroundColor:'#D3D3D3', opacity:'0.85', height:'100vh', width:'100vw', display:'flex', justifyContent:'center', alignItems:'center'}}>
        <div style={{  textAlign:'center'}}>
          <img
                src={Loader}
                style={{ width: '200px' }}
                alt='Loading...'
              />
          <h3 style={{color:'white'}}>Loading...</h3>
        </div>
      </div>
      )}
    <Card
      {...rest}
      className={className}
    >

      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader={props.intl.formatMessage({ id: 'info_can_be_edit' })}
          title={props.intl.formatMessage({ id: 'profile' })}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <label><FormattedMessage id='name' /></label>
              <TextField
                fullWidth
                helperText={props.intl.formatMessage({ id: 'pls_specify_name' })}
                margin="dense"
                name="name"
                onChange={handleChange}
                value={props.account.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <label><FormattedMessage id='email_address' /></label>
              <TextField
                fullWidth
                margin="dense"
                name="email"
                disabled="true"
                onChange={handleChange}
                value={props.account.email}
                variant="filled"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <label><FormattedMessage id='phone_number' /></label>
              <TextField
                fullWidth
                margin="dense"
                name="phone"
                onChange={handleChange}
                value={props.account.phone}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <label><FormattedMessage id='compony' /></label>
              <TextField
                fullWidth
                margin="dense"
                name="compony"
                onChange={handleChange}
                value={props.account.compony}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <label style={{ margin: '0 0 7px 0' }}><FormattedMessage id='language' /></label>
                <Select
                  style={{ width: '100%'}}
                  id="language-select-outlined"
                  value={displayLang || props.account.language}
                  name='language'
                  onChange={(event) => {
                    setDisplayLang(event.target.value);
                  }}
                >
                  {
                    LANGUAGE_LIST.map((langCode) => {
                      return <MenuItem value={langCode}>{langCode}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            {/*
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Organization"
                margin="dense"
                name="organization"
                onChange={handleChange}
                value={props.account.organization}
                variant="outlined"
                disabled
              />
            </Grid>
            */}
          </Grid>
        </CardContent>
        <Divider />
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={1}
            xs={2}
          >
            <CardActions>
              <Button
                variant="contained"
                onClick={handleSaveProfile}
              >
                <FormattedMessage id='save' />
              </Button>
            </CardActions>
          </Grid>
          <Grid
            item
            md={1}
            xs={2}
          >
            <CardActions>
              <Button
                variant="outlined"
                onClick={() => {
                  setDisplayLang(originProfile.language);
                  props.setAccountInfo({...originProfile});
                }}
              >
                <FormattedMessage id='reset' />
              </Button>
            </CardActions>
          </Grid>
        </Grid>
      </form>
    </Card>
    </>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => {
  const { account, auth, loading } = state;
  return { account, auth, loading };
};

export default connect(mapStateToProps, { setAccountInfo, setLoading })(withAuthenticationRequired(injectIntl(AccountDetails)));
