/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useAuth0 } from "@auth0/auth0-react";
import { List, ListItem, Button } from '@mui/material';
import InputIcon from '@mui/icons-material/Input';

import layoutCss from '../../../../../../assets/scss/layout.module.scss';


const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { pages, className, ...rest } = props;
  const { logout } = useAuth0();

  return (
    <List
      {...rest}
      className={clsx(layoutCss.MainSidebarSidebarNav, className)}
    >
      {pages.map(page => (
        <ListItem
          className={layoutCss.MainSidebarSidebarNavItem}
          disableGutters
          key={page.title}
        >
          <Button
            activeClassName={layoutCss.MainSidebarSidebarNavActive}
            className={layoutCss.MainSidebarSidebarNavButton}
            component={CustomRouterLink}
            to={page.href}
          >
            <div className={layoutCss.MainSidebarSidebarNavIcon}>{page.icon}</div>
            {page.title}
          </Button>
        </ListItem>
      ))}
      <ListItem
        className={layoutCss.MainSidebarSidebarNavItem}
        disableGutters
      >
        <Button
          activeClassName={layoutCss.MainSidebarSidebarNavActive}
          className={layoutCss.MainSidebarSidebarNavButton}
          onClick={() => logout()}
        >
          <div className={layoutCss.MainSidebarSidebarNavIcon}><InputIcon /></div>
          Logout
        </Button>
      </ListItem>
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
