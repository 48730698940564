import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withAuthenticationRequired } from '@auth0/auth0-react';
// material-ui component
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import EmailDialog from './emailDialog';
import 'react-confirm-alert/src/react-confirm-alert.css';
// other component
import { confirmAlert } from 'react-confirm-alert';
// cust component
import {
  FieldLayout,
  FormControlFieldLayout,
  RefreshButtonLayout
} from '../../components/CustComponents/CustLayout';
import FormViewer from '../../components/CustComponents/FormViewer';
import { DatePicker } from '../../components/CustComponents/DatePicker';
// cust util
import { httpRequest, reqObj2From, form2ReqObj, mapsConst } from '../../lib';
import { setDealList, setLoading, setDataInitStatus } from '../../actions';
import { validateEngine } from './dealUtil';
// data and css
import envConfig from '../../configuration/env.json';
import validateRules from '../../configuration/dealListValidation.json';
import viewCss from '../../assets/scss/view.module.scss';

const INIT_FORM = {};
const NEW_CASE_TASK_LOG_TOGGLE = false;
const UPDATE_CASE_TASK_LOG_TOGGLE = true;

const DISPLAY_DATA_FORM = false;
const DEFAULT_DATA_FORM_INDEX = 0; // -1 means no default data form

const dataValidation = (isNew, form, attributes) => {
  // check required fields - dataForm
  if (isNew && !form.dataForm) {
    return {
      status: false,
      message: 'Missing data form',
      messageId: 'missing_data_form'
    };
  }
  return validateEngine(form, attributes, validateRules);
};


const changeStemp = (originRow, row, statusKey, editerKey, dateKey, userName, stampStatus, cancelStatus, now) => {
  if (originRow[statusKey] === row[statusKey]) {
    return row;
  }

  // cancelStatus => stampStatus
  if (stampStatus.includes(row[statusKey])) {
    row[editerKey] = userName || 'N/A';
    row[dateKey] = now;
  } else {
    row[editerKey] = null;
    row[dateKey] = null;
  }
  return row;
};

const custChangeStemp = (originRow, row, userName) => {
  const now = new Date();
  // accounting_posted change
  changeStemp(
    originRow,
    row,
    'accounting_posted',
    'accounting_posted_by',
    'accounting_posted_time',
    userName,
    ['posted', 'declined', 'partial_posted'],
    [null, '', 'unposted'],
    now
  );
  // handled_confirmed change
  changeStemp(
    originRow,
    row,
    'handled_confirmed',
    'handled_confirmed_by',
    'handled_confirmed_date',
    userName,
    ['confirmed'],
    [null, '', 'unconfirmed'],
    now
  );
  // review_status change
  changeStemp(
    originRow,
    row,
    'review_status',
    'reviewer',
    'reviewed_date',
    userName,
    ['reviewed', 'partial_reviewed'],
    [null, '', 'not_reviewed'],
    now
  );
};

const renderFormViewerfieldsInfo = (attrNames, attrInfos, formValue) => {
  // 先filter再sort，避免出現attrNames存在但attrInfos不存在情況。
  const targetAttrs = attrInfos.filter((aInfo) => {
    return attrNames.includes(aInfo.field_key);
  });
  const viewerFieldsInfo = attrNames.map((name) => {
    const attr = targetAttrs.find(item => item.field_key === name);
    // edit_stage_field
    const editable = (attr.edit_stage_field && attr.edit_stages.length > 0)
      ? attr.edit_stages.includes(formValue[attr.edit_stage_field]) : true; // (isNew || name !== 'data_form');
    return {
      editable,
      required: attr.required,
      descriptionId: '',
      description: attr.description,
      fieldLabelId: attr.field_label_id,
      fieldKey: attr.field_key,
      fieldType: attr.field_type,
      lookup: attr.lookup
    };
  });
  return viewerFieldsInfo;
};

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ float: 'left', marginRight:'10px' }}>
    <RouterLink {...props} />
  </div>
));

// *******************************************************************************************
// Main Component
// *******************************************************************************************
const DealListDetail = (props) => {
  const navigate = useNavigate();

  // const [openBackdrop, setOpenBackdrop] = React.useState(false); // Integrated into redux loading
  const [deal_index, set_deal_index] = React.useState(-1);
  const [isNew, setIsNew] = React.useState(false);
  const [ownerUid, setOwnerUid] = React.useState('');
  const [isOwnerEdited, setIsOwnerEdited] = React.useState(false); // check owner is edited
  const [isFormEdited, setIsFormEdited] = React.useState(false); // check form is edited
  const [openEmailDialog, setOpenEmailDialog] = React.useState(false); // show email dialog
  const [sheetDataFormList, setSheetDataFormList] = React.useState([]);
  const [sheetDataFormIndex, setSheetDataFormIndex] = React.useState(-1);
  const [formValue, setFormValue] = React.useState({});

  const initForm = (dealList) => {
    const { pathname } = window.location;
    const formId = pathname.split('/').slice(-1)[0];
    if (formId === 'new') {
      const newForm = JSON.parse(JSON.stringify(INIT_FORM));
      setIsNew(true);
      setIsOwnerEdited(false);
      setIsFormEdited(false);
      setSheetDataFormIndex(DEFAULT_DATA_FORM_INDEX);
      setSheetDataFormList(props.sheetDataForm.map((item) => {
        return { label: item.name, value: item.name };
      }));
      newForm.dataForm = props.sheetDataForm[DEFAULT_DATA_FORM_INDEX].name;
      setFormValue(newForm);
      return;
    }
    // 儲存每一張dataform名稱與的id鍵map表，
    const dataFormIdKeyMap = {};
    props.sheetDataForm.forEach((sdInfo) => {
      dataFormIdKeyMap[sdInfo.name] = sdInfo.sheet_id_key;
    });
    // 找出dealList中符合formId的index
    const index = dealList.findIndex((deal) => {
      const idKey = dataFormIdKeyMap[deal.dataForm];
      return deal[idKey] === formId;
    });

    if (index === -1) {
      // TODO: reload data in here. or redirect to back page
      // redirect to back page
      navigate('/pipeline');
      return;
    }

    const fiIndex = props.sheetDataForm.findIndex((fi) => {
      return fi.name === dealList[index].dataForm;
    });
    set_deal_index(index); // deal current index
    setIsOwnerEdited(false);
    setIsFormEdited(false);
    setFormValue({ ...dealList[index] });
    setOwnerUid(dealList[index].owner_uid);
    setSheetDataFormList(props.sheetDataForm.map((item) => {
      return { label: item.name, value: item.name };
    }));
    setSheetDataFormIndex(fiIndex); // set sheetDataForm index
  };

  const initDealList = (token, callback) => {
    httpRequest('GET', '/api/dealtrack', {}, token, (statusCode, response) => {
      const { status, message, data } = response; // { status, message, data }
      if (!status) {
        if (typeof callback === 'function') {
          callback(status, message, []);
        }
        return;
      }
      props.setDealList(data);
      callback(status, message, data);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // const { pathname } = window.location;
    if (props.dataInitStatus.sheetDataForm) {
      if (props.dataInitStatus.dealList) {
        initForm(props.dealList);
      } else {
        props.setLoading(true);
        initDealList(props.auth.accessToken, (iStatus, iMessage, iData) => {
          props.setLoading(false);
          props.setDataInitStatus({ dealList: true });
          initForm(iData);
        });
      }
    }
  }, [props.dataInitStatus.sheetDataForm]); // let it run one time when mount.

  const onSubmitEmail = (form) => {
    /*
    form: {
      receiver: Array<string>,
      ccReceiver: Array<string>,
      bccReceiver: Array<string>,
      emailSubject: string,
      emailContent: string
    }
    */
    props.setLoading(true);
    httpRequest('POST', '/api/email', form, props.auth.accessToken, (statusCode, body) => {
      // TODO: add response here
      setOpenEmailDialog(false);
      props.setLoading(false);
      confirmAlert({
        title: body.status ? 'Success' : 'Warning',
        message: body.status ? 'Success' : body.message,
        buttons: [{ label: 'Yes' }],
        closeOnClickOutside: false
      });
    });
  };

  const onSubmitForm = async (event) => {
    const vaildateInfo = dataValidation(isNew, formValue, props.sheetDataForm[sheetDataFormIndex].attributes);

    if (!vaildateInfo.status) {
      confirmAlert({
        title: 'Warning',
        message: props.intl.formatMessage({ id: vaildateInfo.messageId }),
        closeOnClickOutside: false,
        buttons: [{ label: 'Confirm' }]
      });
      return;
    }

    props.setLoading(true);
    const { sheet_id_key: idKey } = props.sheetDataForm[sheetDataFormIndex];
    const method = isNew ? 'PUT' : 'POST';
    const endpoint = isNew ? `/api/dealtrack/rows/${formValue.dataForm}` : `/api/dealtrack/${formValue.dataForm}/${formValue[idKey]}`;

    const reqBody = isNew ? { rows: [formValue] } : { data: formValue };

    let dialogMsgBody = { status: true, message: 'Success' };
    if (dialogMsgBody.status && isFormEdited) {
      dialogMsgBody = await new Promise((resolve, reject) => {
        httpRequest(method, endpoint, reqBody, props.auth.accessToken, (statusCode, body) => {
          setIsFormEdited(false);
          resolve(body);
        });
      });
    }

    if (dialogMsgBody.status && isOwnerEdited) {
      dialogMsgBody = await new Promise((resolve, reject) => {
        const chOwnerBody = {
          caseUid: formValue[idKey],
          userUid: ownerUid, // changed owner
          dataForm: props.sheetDataForm[sheetDataFormIndex].name
        };
        httpRequest('post', '/api/manager/chowner', chOwnerBody, props.auth.accessToken, (statusCode, body) => {
          resolve(body);
        });
      });
    }

    if (!dialogMsgBody.status) {
      // show error
      confirmAlert({
        title: 'Warning',
        message: dialogMsgBody.message,
        buttons: [{ label: 'Yes', onClick: () => navigate(-1) }],
        closeOnClickOutside: false
      });
      props.setLoading(false);
      return;
    }

    if (UPDATE_CASE_TASK_LOG_TOGGLE && !isNew || NEW_CASE_TASK_LOG_TOGGLE && isNew) {
      // 將當前更動資料寫入redux
      // 新增的缺少case id所以無法更新至redux
      if (!isNew) {
        const { dealList } = props;
        if (isFormEdited) {
          custChangeStemp(dealList[deal_index], formValue, props.account.name);
          formValue.sys_last_update_stamp = new Date();
          dealList[deal_index] = formValue;
        }
        if (isOwnerEdited) {
          setIsOwnerEdited(false);
          const newOwnerInfo = props.orgMember.find((om) => om.user_uid === ownerUid);
          dealList[deal_index].owner_email = newOwnerInfo.email;
          dealList[deal_index].owner_name = newOwnerInfo.name;
          dealList[deal_index].owner_uid = ownerUid;
        }
        props.setDealList(dealList);
      }
      props.setLoading(false);
      confirmAlert({
        title: 'Success',
        message: 'Success',
        buttons: [{ label: 'Yes', onClick: () => navigate(-1)  }],
        closeOnClickOutside: false
      });
    } else {
      setIsOwnerEdited(false);
      httpRequest('GET', '/api/dealtrack', {}, props.auth.accessToken, (statusCode, response) => {
        const { status, message, data } = response;
        props.setLoading(false);
        if (!status) {
          confirmAlert({
            title: 'Warning',
            message: 'Reload data failed',
            buttons: [{ label: 'Yes', onClick: () => navigate(-1)  }],
            closeOnClickOutside: false
          });
          return;
        }
        confirmAlert({
          title: 'Success',
          message: 'Success',
          buttons: [{ label: 'Yes', onClick: () => navigate(-1)  }],
          closeOnClickOutside: false
        });
        props.setDealList(data);
      });
    }
  };

  const onDataFormChange = (key, newForm) => {
    const fIndex = sheetDataFormList.findIndex((form) => {
      return form.value === newForm[key];
    });
    // change form layout
    setFormValue(newForm);
    setSheetDataFormIndex(fIndex);
  };

  const refreshExchangeRate = () => {
    const exchangeUrl = formValue.exchange_bank ? `/api/bank/exchangerate/${formValue.exchange_bank}` : `/api/bank/exchangerate`
    const exchangeDate = formValue.exchange_date ? formValue.exchange_date : new Date();
    const exchangeOperate = formValue.exchange_operate ? formValue.exchange_operate : 'bank_sell_cash';
    const exchangeCurrency = formValue.currency ? formValue.currency : 'USD';

    const url = `${exchangeUrl}?target=${exchangeCurrency}&date=${exchangeDate}`;
    httpRequest('GET', url, {}, props.auth.accessToken, (statusCode, body) => {
      if (!body.status) {
        confirmAlert({
          title: 'Warning',
          message:  body.message,
          buttons: [{ label: 'Yes' }],
          closeOnClickOutside: false
        });
        return;
      }
      setFormValue({
        ...formValue,
        exchange_bank: body.exchangeInfo.bank,
        exchange_date: body.exchangeInfo.date,
        exchange_operate: exchangeOperate,
        exchange_rate: body.exchangeInfo[exchangeOperate],
        currency: body.exchangeInfo.currency
      });
    });
  };

  const onBtnClick = ({ fieldType }) => {
    if (fieldType === 'rate_field') {
      refreshExchangeRate();
    }
  };

  return (deal_index === -1 && !isNew) ? null : (
    <div className={viewCss.DealListDetail}>
      <Paper className={viewCss.DealListDetailPaper}>
        {
          DISPLAY_DATA_FORM && (
            <>
              <div style={{ padding: '10px 0px'}}>
                <div style={{ padding: '20px 0px'}}>
                  <h2><FormattedMessage id={'data_form'}/></h2>
                </div>
                <FormViewer
                  intl={props.intl}
                  onFormChange={onDataFormChange}
                  datas={formValue}
                  fieldsInfo={[{
                    editable: isNew,
                    required: true,
                    fieldLabelId: 'data_form',
                    fieldKey: 'dataForm',
                    fieldType: 'select',
                    lookup: sheetDataFormList
                  }]}
                />
              </div>
              <Divider />
            </>
          )
        }
        {
          (sheetDataFormIndex > -1) && props.sheetDataForm[sheetDataFormIndex].layout.classes.map((layoutInfo) => {
            const viewerfieldsInfo = renderFormViewerfieldsInfo(layoutInfo.attr, props.sheetDataForm[sheetDataFormIndex].attributes, formValue);
            return (
              <>
                <div style={{ padding: '10px 0px'}}>
                  <div style={{ padding: '20px 0px'}}>
                    <h2>
                      <FormattedMessage id={layoutInfo.title_id} />
                    </h2>
                  </div>
                  <FormViewer
                    intl={props.intl}
                    onBtnClick={onBtnClick}
                    onFormChange={(key, newForm) => {
                      setIsFormEdited(true);
                      setFormValue(newForm);
                    }}
                    datas={formValue}
                    fieldsInfo={viewerfieldsInfo}
                  />
                </div>
                <Divider />
              </>
            );
          })
        }
      </Paper>
      <Paper className={viewCss.DealListDetailPaper}>
        <p>
          審核狀況:
            未審核 => 已審核，將會帶入審核時間與經手人
            已審核 => 未審核，將會移除審核時間與經手人
        </p>
      </Paper>
      <div style={{ padding: '10px 0px', position: 'fixed', bottom: '10px', right: '30px' }}>
        <Button style={{ width: '90px' }} variant="contained" onClick={() => navigate(-1)}>
          <FormattedMessage id='back'/>
        </Button>
        {
          ['Archive', 'Expired'].includes(formValue.case_status) && props.account.role !== 'admin' ?
          null : <Button style={{ width: '90px' }} variant="contained" color="primary" onClick={onSubmitForm}>
            <FormattedMessage id={isNew ? 'add' : 'update'}/>
          </Button>
        }
      </div>
      {/* <EmailDialog
        open={openEmailDialog}
        onClose={() => {
          setOpenEmailDialog(false);
        }}
        orgMember={props.orgMember}
        ownerEmail={formValue.owner_email}
        onSubmitEmail={onSubmitEmail}
        form={formValue}
      /> */}
    </div>
  );
};

const mapStateToProps = state => {
  const { auth, dealList, loading, account, notifyRule, orgMember, sheetDataForm, dataInitStatus } = state;
  return { auth, dealList, loading, account, notifyRule, orgMember, sheetDataForm, dataInitStatus };
};

export default connect(mapStateToProps, { setDealList, setLoading, setDataInitStatus })(withAuthenticationRequired(injectIntl(DealListDetail)));
