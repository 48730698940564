import { CONSTANTS } from "./constants";

type LAYOUT_TYPE = {
  initUseAttr: {
    [attKey: string]: any
  },
  classes: Array<{
    title_id: string,
    attr: Attay<string>
  }>
};

type ATTRIBUTE_TYPE = {
  column: string,
  column_index: number,
  name: string,
  description: string,
  edit_stage_field: string,
  edit_stages: Array<string>,
  required: boolean,
  display: boolean,
  private: boolean,
  admin_role: Array<string>,
  field_label_id: string,
  field_key: string,
  field_type: string,
  lookup: Array<{
    label: string, label_id: string, value: any
  }>
};

type SHEET_DATA_FORM_TYPE = {
  name: string,
  description: string,
  sheet_id_key: string,
  sheet_status_key: string,
  sheet_status_update_key: string,
  sheet_user_id_key: string,
  sheet_org_key: string,
  crm_update_mode: string,
  crm_org: Array<string>,
  tags: Array<string>,
  layout: LAYOUT_TYPE,
  attributes: Array<ATTRIBUTE_TYPE>
};

const initialSheetDataForm: Array<SHEET_DATA_FORM_TYPE> = [];

export default (state = initialSheetDataForm, action) => {
  switch (action.type) {
    case CONSTANTS.SET_SHEET_DATA_FORM :
      return action.value;
    default:
      return state;
  }
}
