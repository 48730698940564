import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Toolbar } from '@mui/material';
import layoutCss from '../../../../assets/scss/layout.module.scss';

const Topbar = props => {
  const { className, ...rest } = props;

  return (
    <AppBar
      {...rest}
      className={clsx(className, layoutCss.AppBar)}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/logo--white.svg"
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
