import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { connect } from 'react-redux';

import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';

import { EnhancedTableHead, TableBodyRow } from '../../components/TableComponent';
import {
  setAccountInfo,
  setOrgMember,
  setDealList,
  setLoading,
  setAuditFilterInfo,
  setAuditOrderInfo,
  setAuditResults
} from '../../actions'; // redux action
import { generateMonthTags } from './auditUtil';
import { InfiniteScrollMutiPickSelect } from '../../components/CustComponents/MutiPickSelect';

import { httpRequest } from '../../lib';
import tableInfo from '../../configuration/auditTableInfo.json';

import viewCss from '../../assets/scss/view.module.scss';

const FILTER_DISPLAY = 'flatten'; // 'accordion' | 'flatten' | 'dialog'

const FILTER_FORM = {};
const TABLE_PER_PAGE = -1; // -1 disable page

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const Audit = (props) => {
  const [tableHeight, setTableHeight] = React.useState(70);
  const [successRowIndex, setSuccessRowIndex] = useState(-1);
  const [warnRowIndex, setWarnRowIndex] = useState(-1);
  const [warnRuleRowIndex, setWarnRuleRowIndex] = useState(-1);

  const [rowsPerPage, setRowsPerPage] = React.useState(TABLE_PER_PAGE);
  const [monthTagOptions, setMonthTagOptions] = React.useState([]);

  useEffect(() => {
    // fetch data
    const mtOptions = generateMonthTags(0, 24).map((tagStr) => {
      const labelStr = tagStr.replace(/-|_/g, '/');
      return { label: labelStr, value: tagStr };
    });
    setMonthTagOptions(mtOptions);
  }, []);
  // import by excel
  // show data

  const handleRequestSort = (order, orderBy, setOrderKey, setOrderByKey, setRowIndex) => (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    props.setAuditOrderInfo({
      [setOrderKey]: isAsc ? 'desc' : 'asc',
      [setOrderByKey]: property
    })

    setRowIndex(-1);
  };
  const onAuditClick = (event) => {
    const optBody = {
      tags: props.auditFilterInfo.dateTags,
      dateForm: props.auditSheetDataForm[0].name
    };
    props.setLoading(true);
    httpRequest('POST', '/api/audit/check', optBody, props.auth.accessToken, (statusCode, body) => {
      props.setLoading(false);
      if (body.status) {
        props.setAuditResults({
          sheetRowSuccessResults: body.sheetRowSuccessResults,
          sheetRowWarnResults: body.sheetRowWarnResults,
          unmatchedRules: body.unmatchedRules,
        });
        // setSuccessRows(body.sheetRowSuccessResults); // sheetRowSuccessResults
        // setWarnRows(body.sheetRowWarnResults); // sheetRowWarnResults
        // setWarnRuleRows(body.unmatchedRules); // unmatchedRules
      }
    });
  };
 
  const warnViewSrcFunc = (row, rowIndex, headCell, headCellIndex) => {
    return `/pipeline/${row.id}`;
  };
  const warnCustValueFunc = (row, rowIndex, headCell, headCellIndex) => {
    const warnMsgs = props.auditResults.sheetRowWarnResults[rowIndex].results.map((warnResult) => {
      return `${warnResult.rule.name}-${warnResult.message}`;
    });
    return warnMsgs.join('\n');
  };

  const warnRuleViewSrcFunc = (row, rowIndex, headCell, headCellIndex) => {
    return `/audit_datas/${row.sys_id}`;
  };

  const successViewSrcFunc = (row, rowIndex, headCell, headCellIndex) => {
    return `/pipeline/${row.id}`;
  };

  const displayWarnHeaders =  tableInfo.warnHeaders.filter((tableHeader) => {
    return (tableHeader.role.length === 0) || tableHeader.role.includes(props.account.role);
  });

  const displayWarnRulesHeaders =  tableInfo.warnRulesHeaders.filter((tableHeader) => {
    return (tableHeader.role.length === 0) || tableHeader.role.includes(props.account.role);
  });
  const displaySuccessHeaders =  tableInfo.successHeaders.filter((tableHeader) => {
    return (tableHeader.role.length === 0) || tableHeader.role.includes(props.account.role);
  });

  const tableContainerOffset = (rowsPerPage > 0) ? tableHeight + 166 : tableHeight + 105;
  const {
    successOrder,
    successOrderBy,
    warnOrder,
    warnOrderBy,
    unmatchedOrder,
    unmatchedOrderBy
  } = props.auditOrderInfo;
  const displayWarnRows = props.auditResults.sheetRowWarnResults.map((warnRowInfo) => {
    return warnRowInfo.shetRow;
  });
  const displaySuccessRows = props.auditResults.sheetRowSuccessResults.map((successRowInfo) => {
    return successRowInfo.shetRow;
  });
  return (
    <>
      <div className={viewCss.Audit}>
        <Paper className={viewCss.AuditPaper}>
          <div style={{ margin: '0px 0px 25px 0px', height: `${tableHeight}px`}}>
            <h1 style={{ float: 'left', margin: '10px 20px' }}>
              <FormattedMessage id={'audit'} />
            </h1>
            <InfiniteScrollMutiPickSelect
              enable={true}
              style={{ minWidth: '150px' }}
              title={props.intl.formatMessage({ id: 'pick_tags' })}
              value={props.auditFilterInfo.dateTags}
              options={monthTagOptions}
              onChange={(event) => {
                props.setAuditFilterInfo({ ...props.auditFilterInfo, dateTags: event.target.value });
              }}
              onScrollBottom={(event) => {
                const mtOptions = generateMonthTags(monthTagOptions.length, 12).map((tagStr) => {
                  const labelStr = tagStr.replace(/-|_/g, '/');
                  return { label: labelStr, value: tagStr };
                });
                setMonthTagOptions(monthTagOptions.concat(mtOptions));
              }}
            />
            {/* // TODO: cust date range tags picker. */}
            <Tooltip title={props.intl.formatMessage({ id: 'audit' })}>
              <IconButton onClick={onAuditClick}>
                <SearchIcon fontSize='large' color='primary'/>
              </IconButton>
            </Tooltip>
          </div>
          <h2 style={{ float: 'left', margin: '10px 20px' }}>
            <FormattedMessage id={'warn_rows'} />
            {displayWarnRows.length > 0 && `: ${displayWarnRows.length}`}
          </h2>
          <TableContainer>
            <Table
              stickyHeader
              className={viewCss.AuditTable}
              aria-labelledby="tableTitle"
              size={'small'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                onRequestSort={handleRequestSort(warnOrder, warnOrderBy, 'warnOrder', 'warnOrderBy', setWarnRowIndex)}
                heads={displayWarnHeaders}
                order={warnOrder}
                orderBy={warnOrderBy}
              />
              <TableBodyRow
                rows={displayWarnRows}
                headers={displayWarnHeaders}
                viewSrcFunc={warnViewSrcFunc}
                custValueFunc={warnCustValueFunc}
              />
            </Table>
          </TableContainer>
        </Paper>

        <Paper className={viewCss.AuditPaper}>
          <h2 style={{ float: 'left', margin: '10px 20px' }}>
            <FormattedMessage id={'warn_rule'} />
            {props.auditResults.unmatchedRules.length > 0 && `: ${props.auditResults.unmatchedRules.length}`}
          </h2>
          <TableContainer>
            <Table
              stickyHeader
              className={viewCss.AuditTable}
              aria-labelledby="tableTitle"
              size={'small'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                onRequestSort={handleRequestSort(unmatchedOrder, unmatchedOrderBy, 'unmatchedOrder', 'unmatchedOrderBy', setWarnRuleRowIndex)}
                heads={displayWarnRulesHeaders}
                order={unmatchedOrder}
                orderBy={unmatchedOrderBy}
              />
              <TableBodyRow
                rows={props.auditResults.unmatchedRules}
                headers={displayWarnRulesHeaders}
                viewSrcFunc={warnRuleViewSrcFunc}
                custValueFunc={null}
              />
            </Table>
          </TableContainer>
        </Paper>

        <Paper className={viewCss.AuditPaper}>
          <h2 style={{ float: 'left', margin: '10px 20px' }}>
            <FormattedMessage id={'success_row'} />
            {displaySuccessRows.length > 0 && `: ${displaySuccessRows.length}`}
          </h2>
          <TableContainer>
            <Table
              stickyHeader
              className={viewCss.AuditTable}
              aria-labelledby="tableTitle"
              size={'small'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                onRequestSort={handleRequestSort(successOrder, successOrderBy, 'successOrder', 'successOrderBy', setSuccessRowIndex)}
                heads={displaySuccessHeaders}
                order={successOrder}
                orderBy={successOrderBy}
              />
              <TableBodyRow
                rows={displaySuccessRows}
                headers={displaySuccessHeaders}
                viewSrcFunc={successViewSrcFunc}
                custValueFunc={null}
              />
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const { auth, dealList, loading, account, notifyRule, orgMember, authInfo, auditSheetDataForm, auditFilterInfo, auditOrderInfo, auditResults } = state;
  return { auth, dealList, loading, account, notifyRule, orgMember, authInfo, auditSheetDataForm, auditFilterInfo, auditOrderInfo, auditResults };
};

export default connect(mapStateToProps, { setLoading, setAuditFilterInfo, setAuditOrderInfo, setAuditResults })(withAuthenticationRequired(injectIntl(Audit)));
