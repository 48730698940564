
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
// material-ui
import CreateIcon from '@mui/icons-material/Create';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';
// custom component
import FilterComponents from '../FilterComponent';

const FILTER_ACCORDION_STR = 'accordion';
const FILTER_FLATTEN_STR = 'flatten';
const FILTER_DIALOG_STR = 'dialog';

const MOBILE_SCREEN_STR = 'mobile';
const MEDIUM_SCREEN_STR = 'medium';
const LARGE_SCREEN_STR = 'large';

const SCREEN_SIZE_INFO_MAP = {
  [MOBILE_SCREEN_STR]: { display: FILTER_DIALOG_STR },
  [MEDIUM_SCREEN_STR]: { display: FILTER_ACCORDION_STR },
  [LARGE_SCREEN_STR]: { display: FILTER_FLATTEN_STR }
};

/* ************************
const FILTER_INFOS = Array<{
  name: string,
  description: string,
  label: string,
  label_id: string,
  placeholder: Array<string>,
  placeholder_id: Array<string>,
  type: string, // data_range, string, number, date
  keys: Array<string>, // array for multi keys.
  lookup: Array<{ label: string, label_id: ?string, value: any }>,
  condition: string // equal more less or range
}>
<TableBar
  title={''}
  screen={''} // 'mobile' | 'medium' | 'large'
  onAddClick={() => {}} // option
  onRefreshClick={() => {}} // option
  onFilterFormChange={(key, value) => {
    const json = JSON.parse(JSON.stringify(filterForm));
    json[key] = value;
    setFilterInfos(json);
  }}
  filterInfos={FILTER_INFOS}
  filterForm={filterForm}
/>
************************ */

const TableBar = (props) => {
  return (
    <>
      {props.title && <h1 style={{ float: 'left', margin: '10px 20px' }}>{props.title}</h1>}
      <div style={{ float: 'right' }}>
        <FilterComponents
          display={SCREEN_SIZE_INFO_MAP[props.screen].display}
          filterForm={props.filterForm}
          filterInfos={props.filterInfos}
          onFilterFormChange={props.onFilterFormChange}
          onFilterWHChange={props.onFilterWHChange}
        />
      </div>
      <Tooltip title={props.intl.formatMessage({ id: 'refresh' })}>
        <IconButton onClick={props.onRefreshClick} style={{ float: 'right' }}>
          <RefreshIcon fontSize='large' color='primary'/>
        </IconButton>
      </Tooltip>
      {
        (typeof props.onAddClick === 'function') &&
        <Tooltip title={props.intl.formatMessage({ id: 'add' })}>
          <IconButton onClick={props.onAddClick} style={{ float: 'right' }}>
            <CreateIcon fontSize='large' color='primary'/>
          </IconButton>
        </Tooltip>
      }
    </>
  );
};

TableBar.propTypes = {
  title: PropTypes.string,
  screen: PropTypes.string,
  onAddClick: PropTypes.func,
  onRefreshClick: PropTypes.func.isRequired,
  onFilterFormChange: PropTypes.func.isRequired,
  filterInfos: PropTypes.array.isRequired,
  filterForm: PropTypes.object.isRequired
};

export default injectIntl(TableBar);
