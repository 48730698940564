import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Grid2 from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { NumericFormat } from 'react-number-format';
import { MutiPickSelect } from './MutiPickSelect';
import RefreshIcon from '@mui/icons-material/Refresh';

const GridInputSize = { md: 4, sm: 6, xs: 12 };

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const FormViewer = ({ intl, onFormChange, onBtnClick, formTitle, datas, fieldsInfo, ...props }) => {
  return (
    <>
      {formTitle && <h1 {...props}>{formTitle}</h1>}
      <Grid2 {...props} container spacing={2}>
        {fieldsInfo.map((field, fieldIndex) => {
          const { fieldKey, fieldType, editable, fieldLabelId, grid = GridInputSize, lookup } = field;
          if (fieldType === 'rate_field') {
            return (
              <Grid2 key={`${fieldLabelId}-${fieldIndex}`} md={grid.md || GridInputSize.md} sm={grid.sm || GridInputSize.sm} xs={grid.xs || GridInputSize.xs}>
                <TextField
                  style={{ width: 'calc(100% - 40px)' }}
                  label={intl.formatMessage({ id: fieldLabelId })}
                  id={`field-${fieldIndex}`}
                  disabled={!editable}
                  value={datas[fieldKey]}
                  type="number"
                  size="small"
                  fullWidth
                  onChange={(event) => {
                    const d = JSON.parse(JSON.stringify(datas));
                    d[fieldKey] = event.target.value;
                    onFormChange(fieldKey, d);
                  }}
                />

                <IconButton aria-label="refresh" onClick={() => {
                  if (onBtnClick) {
                    onBtnClick(field, datas[fieldKey]);
                  }
                }}>
                  <RefreshIcon />
                </IconButton>
              </Grid2>
            );
          }
          if (fieldType === 'boolean') {
            return (
              <Grid2 key={`${fieldLabelId}-${fieldIndex}`} md={grid.md || GridInputSize.md} sm={grid.sm || GridInputSize.sm} xs={grid.xs || GridInputSize.xs}>
                <FormControlLabel
                  disabled={!editable}
                  label={intl.formatMessage({ id: fieldLabelId })}
                  control={<Switch
                    checked={datas[fieldKey] || false}
                    onChange={(event) => {
                      const d = JSON.parse(JSON.stringify(datas));
                      d[fieldKey] = event.target.checked;
                      onFormChange(fieldKey, d);
                    }}
                  />}
                />
              </Grid2>
            );
          }

          if (fieldType === 'number') {
            return (
              <Grid2 key={`${fieldLabelId}-${fieldIndex}`} md={grid.md || GridInputSize.md} sm={grid.sm || GridInputSize.sm} xs={grid.xs || GridInputSize.xs}>
                <TextField
                  label={intl.formatMessage({ id: fieldLabelId })}
                  id={`field-${fieldIndex}`}
                  disabled={!editable}
                  value={datas[fieldKey]}
                  type="number"
                  size="small"
                  fullWidth
                  onChange={(event) => {
                    const d = JSON.parse(JSON.stringify(datas));
                    d[fieldKey] = event.target.value;
                    onFormChange(fieldKey, d);
                  }}
                />
              </Grid2>
            );
          }

          if (fieldType === 'date') {
            const dateValue = datas[fieldKey] ? new Date(datas[fieldKey]) : null;
            return (
              <Grid2 key={`${fieldLabelId}-${fieldIndex}`} md={grid.md || GridInputSize.md} sm={grid.sm || GridInputSize.sm} xs={grid.xs || GridInputSize.xs}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    maxDate={new Date('9999-12-31')}
                    sx={{ width: '100%' }}
                    label={intl.formatMessage({ id: fieldLabelId })}
                    value={dateValue}
                    disabled={!editable}
                    format="yyyy-MM-dd"
                    onChange={(value) => {
                      const d = JSON.parse(JSON.stringify(datas));
                      d[fieldKey] = value;
                      onFormChange(fieldKey, d);
                    }}
                    slotProps={{
                      actionBar: {
                        actions: ['clear']
                      }
                    }}
                    PopoverProps={{
                      anchorOrigin: { horizontal: "center", vertical: "bottom" },
                      transformOrigin: { horizontal: "center", vertical: "bottom" }
                    }}
                  />
                </LocalizationProvider>
              </Grid2>
            );
          }
          if (fieldType === 'datetime') {
            const dateValue = datas[fieldKey] ? new Date(datas[fieldKey]) : null;
            return (
              <Grid2 key={`${fieldLabelId}-${fieldIndex}`} md={grid.md || GridInputSize.md} sm={grid.sm || GridInputSize.sm} xs={grid.xs || GridInputSize.xs}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    maxDate={new Date('9999-12-31')}
                    sx={{ width: '100%' }}
                    label={intl.formatMessage({ id: fieldLabelId })}
                    value={dateValue}
                    disabled={!editable}
                    format="yyyy-MM-dd hh:mm:aa"
                    onChange={(value) => {
                      const d = JSON.parse(JSON.stringify(datas));
                      d[fieldKey] = value;
                      onFormChange(fieldKey, d);
                    }}
                    slotProps={{
                      actionBar: {
                        actions: ['clear']
                      }
                    }}
                    slots={{
                      Popper: {
                        anchorOrigin: { horizontal: "center", vertical: "bottom" },
                        transformOrigin: { horizontal: "center", vertical: "bottom" }
                      }
                    }}

                  />
                </LocalizationProvider>
              </Grid2>
            );
          }
          if (fieldType === 'select') {
            return (
              <Grid2 key={`${fieldLabelId}-${fieldIndex}`} md={grid.md || GridInputSize.md} sm={grid.sm || GridInputSize.sm} xs={grid.xs || GridInputSize.xs}>
                <FormControl fullWidth>
                  <InputLabel><FormattedMessage id={fieldLabelId}/></InputLabel>
                  <Select
                    label={intl.formatMessage({ id: fieldLabelId })}
                    displayEmpty
                    value={datas[fieldKey]}
                    disabled={!editable}
                    onChange={(event) => {
                      const d = JSON.parse(JSON.stringify(datas));
                      d[fieldKey] = event.target.value;
                      onFormChange(fieldKey, d);
                    }}
                  >
                    {
                      lookup.map((item, itemIndex) => {
                        return (
                          <MenuItem
                            key={`${item.value || item.label}-${itemIndex}`}
                            value={item.value}
                          >
                            <FormattedMessage id={item.label_id} defaultMessage={item.label} />
                          </MenuItem>
                        );
                      })
                    }
                  </Select>
                </FormControl>
              </Grid2>
            );
          }
          if (fieldType === 'mutiselect') {
            return (
              <Grid2 key={`${fieldLabelId}-${fieldIndex}`} md={grid.md || GridInputSize.md} sm={grid.sm || GridInputSize.sm} xs={grid.xs || GridInputSize.xs}>
                <FormControl fullWidth>
                  <InputLabel><FormattedMessage id={fieldLabelId}/></InputLabel>
                  <Select
                    multiple
                    value={datas[fieldKey]}
                    disabled={!editable}
                    onChange={(event) => {
                      const d = JSON.parse(JSON.stringify(datas));
                      d[fieldKey] = event.target.value;
                      onFormChange(fieldKey, d);
                    }}
                    renderValue={(selected) => {
                      const selectedOptions = lookup.filter((opt) => {
                        return selected.includes(opt.value);
                      });
                      return(
                        <div style={{ display: 'flex', flexWrap: 'wrap' }} >
                          {selectedOptions.map((opt, optIndex) => (<Chip key={`${opt.label}-${optIndex}`} label={opt.label}/> ))}
                        </div>
                      );
                    }}
                    MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } } }}
                  >
                    {
                      lookup.map((item, itemIndex) => {
                        return (
                          <MenuItem
                            key={`${item.value || item.label}-${itemIndex}`}
                            value={item.value}
                          >
                            <FormattedMessage id={item.label_id} defaultMessage={item.label} />
                          </MenuItem>
                        );
                      })
                    }
                  </Select>
                </FormControl>
              </Grid2>
            );
          }
          if (fieldType === 'multiline') {
            return (
              <Grid2 key={`${fieldLabelId}-${fieldIndex}`} md={grid.md || GridInputSize.md} sm={grid.sm || GridInputSize.sm} xs={grid.xs || GridInputSize.xs}>
                <TextField
                  fullWidth
                  multiline
                  label={intl.formatMessage({ id: fieldLabelId })}
                  id={`field-${fieldIndex}`}
                  value={datas[fieldKey] || ''}
                  disabled={!editable}
                  size="small"
                  onChange={(event) => {
                    const d = JSON.parse(JSON.stringify(datas));
                    d[fieldKey] = event.target.value;
                    onFormChange(fieldKey, d);
                  }}
                />
              </Grid2>
            );
          }
          if (fieldType === 'subtitle') {
            return (
              <Grid2 key={`${fieldLabelId}-${fieldIndex}`} md={grid.md || GridInputSize.md} sm={grid.sm || GridInputSize.sm} xs={grid.xs || GridInputSize.xs}>
                <h2>{intl.formatMessage({ id: fieldLabelId })}</h2>
              </Grid2>
            );
          }
          if (fieldType === 'currency') {
            return (
              <Grid2 key={`${fieldLabelId}-${fieldIndex}`} md={grid.md || GridInputSize.md} sm={grid.sm || GridInputSize.sm} xs={grid.xs || GridInputSize.xs}>
                <TextField
                  fullWidth
                  type={'text'}
                  value={datas[fieldKey] || ''}
                  disabled={!editable}
                  variant={!editable ? 'filled' : 'outlined'}
                  onChange={(event) => {
                    const d = JSON.parse(JSON.stringify(datas));
                    d[fieldKey] = event.target.value;
                    onFormChange(fieldKey, d);
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    inputComponent: NumberFormatCustom
                  }}
                />
              </Grid2>
            );
          }
          // default is text field
          return (
            <Grid2 key={`${fieldLabelId}-${fieldIndex}`} md={grid.md || GridInputSize.md} sm={grid.sm || GridInputSize.sm} xs={grid.xs || GridInputSize.xs}>
              <TextField
                fullWidth
                label={intl.formatMessage({ id: fieldLabelId })}
                id={`field-${fieldIndex}`}
                value={datas[fieldKey] || ''}
                disabled={!editable}
                size="small"
                onChange={(event) => {
                  const d = JSON.parse(JSON.stringify(datas));
                  d[fieldKey] = event.target.value;
                  onFormChange(fieldKey, d);
                }}
              />
            </Grid2>
          );
        })}
      </Grid2>
    </>
  );
};

FormViewer.prototype = {
  intl: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onBtnClick: PropTypes.func,
  formTitle: PropTypes.string.isRequired,
  datas: PropTypes.object.isRequired,
  fieldsInfo: PropTypes.array.isRequired
};

export default injectIntl(FormViewer);
