import React, { useEffect, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import PropTypes from 'prop-types';

import FormDialog from '../../components/CustComponents/FormDialog';
import TEMPLATE_LIST from '../../configuration/emailTemplate.json';

const RejectFormDialog = (props) => {
  const [rejectSubject, setRejectSubject] = useState('');
  const [rejectReason, setRejectReason] = useState('');
  const [template, setTemplate] = useState('');

  useEffect(() => {
    setRejectSubject('');
    setRejectReason('');
  }, [props.open]);

  const onSubmit = (event) => {
    const form = {
      subject: rejectSubject,
      content: rejectReason
    };
    props.onSubmit(form);
  };

  const onTemplateChange = (event) => {
    setTemplate(event.target.value);

    const item = TEMPLATE_LIST.find((tmp) => {
      return tmp.key === event.target.value;
    });

    if (item) {
      const newSubject = props.intl.formatMessage({ id: item.subject_id, defaultMessage: item.subject });
      const newContent = props.intl.formatMessage({ id: item.content_id, defaultMessage: item.content });
      setRejectSubject(newSubject);
      setRejectReason(newContent);
    }
  };

  return (
    <FormDialog
      open={props.open}
      title={props.intl.formatMessage({ id: 'reject_title' })}
      saveText={props.intl.formatMessage({ id: 'submit' })}
      closeText={props.intl.formatMessage({ id: 'cancel' })}
      handleSave={onSubmit}
      handleClose={props.onClose}
      maxWidth={'sm'}
      progress={props.progress}
    >
      {/* select template
      <FormControl fullWidth style={{ margin: '10px 0px' }}>
        <label style={{ marginBottom: '-18px', fontSize: '12px' }}><FormattedMessage id='select_email_tmp'/></label>
        <Select
          style={{ width: '100%'}}
          id="email_template_select"
          label={props.intl.formatMessage({ id: 'select_email_tmp' })}
          value={template}
          onChange={onTemplateChange}
        >
          {
            TEMPLATE_LIST.map((item) => {
              return <MenuItem value={item.key}>{item.key}</MenuItem>
            })
          }
        </Select>
      </FormControl> */}
      {/* <TextField
        fullWidth
        label={props.intl.formatMessage({ id: 'subject' })}
        id={'subject'}
        value={rejectSubject}
        size="small"
        onChange={(event) => {
          setRejectSubject(event.target.value);
        }}
      /> */}
      <TextField
        fullWidth
        multiline
        label={props.intl.formatMessage({ id: 'comment' })}
        id={'comment'}
        value={rejectReason}
        size="small"
        onChange={(event) => {
          setRejectReason(event.target.value);
        }}
      />
    </FormDialog>
  );
};

RejectFormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default injectIntl(RejectFormDialog);
