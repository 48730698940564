import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import { setLoading } from '../../../actions';
import FileUploader from '../../../components/CustComponents/FileUploader';

const Pdf2Html = (props) => {
  const [password, setPassword] = React.useState('');
  const [uploadFileName, setUploadFileName] = React.useState('');
  const [preHtml, setPreHtml] = useState('');
  const [displayProgress, setDisplayProgress] = useState(false);

  const fileUpload = (file, callback) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileInfo', JSON.stringify({
      name: file.name,
      mimetype: file.type,
      password
    }));
    const options ={
      method: 'POST',
      url: `/api/tool/pdf2html`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${props.auth.accessToken}`
      }
    };
    axios(options).then((res) => {
      callback(res.data.status, res.data.message, res.data.html);
    }).catch((err) => {
      callback(false, err.message, 'err.message');
    });
  };

  const onFileChange = (file) => {
    if (file) {
      setDisplayProgress(true);
      fileUpload(file, (status, message, html) => {
        setPreHtml(html);
        setDisplayProgress(false);
      });
    }
  };
  return (
    <Paper>
      {displayProgress && <LinearProgress style={{ width: '100%' }}/>}
      <TextField
        fullWidth
        label={props.intl.formatMessage({ id: 'password' })}
        id={`pdf_password`}
        value={password}
        size="small"
        type="password"
        onChange={(event) => {
          setPassword(event.target.value);
        }}
      />
      <FileUploader
        intl={props.intl}
        btnStyle='icon'
        fileName={uploadFileName}
        placeholder={props.intl.formatMessage({ id: 'upload_file' })}
        onFileChange={onFileChange}
      />
      <hr/>
      <a href="/preview.html" target="_blank">open</a>
      <Button/>
      <TextField
        fullWidth
        multiline
        label={props.intl.formatMessage({ id: 'preview' })}
        id={`display_pdf`}
        value={preHtml}
        size="small"
        onChange={(event) => {}}
      />
    </Paper>
  );
};

const mapStateToProps = (state) => {
  const { auth, loading } = state;
  return { auth, loading };
};

export default connect(mapStateToProps, {
  setLoading
})(withAuthenticationRequired(injectIntl(Pdf2Html)));
