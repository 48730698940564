import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const IMG_TAG_TYPES = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'svg', 'webp'];
const IFRAME_TAG_TYPES = ['pdf'];

const FileViewer = (props) => {
  const onDownload = () => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = props.src;
    a.download = props.title; //filename
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  if (props.displayLoading) {
    return (
      <div style={{ width: '100%' }}>
        Loading...
      </div>
    );
  }
  if (IMG_TAG_TYPES.includes(props.fileType.toLowerCase())) {
    return (
      <img style={{ width: '100%' }} src={props.src} alt={props.title} />
    );
  }
  if (IFRAME_TAG_TYPES.includes(props.fileType.toLowerCase())) {
    return (
      <iframe width="100%" height="100%" src={props.src}>
        {props.title}
      </iframe>
    );
  }
  return (
    <dev>
      Unsupported file type<br/>
      <IconButton
        aria-label="view"
        style={{ width: '100%', fontSize: '260px' }}
        onClick={onDownload}
      >
        <CloudDownloadIcon style={{ fontSize: '100%' }} color='primary'/>
      </IconButton>
    </dev>
  );
};

FileViewer.propTypes = {
  title: PropTypes.string.isRequired,
  displayLoading: PropTypes.bool,
  fileType: PropTypes.string.isRequired, // iframe, img, video
  src: PropTypes.string.isRequired
};

export default FileViewer;