import { CONSTANTS } from "./constants";

const AUDIT_DATAS_INIT_INFO = [];

export default (state = AUDIT_DATAS_INIT_INFO, action) => {
  switch (action.type) {
    case CONSTANTS.SET_AUDIT_DATAS:
      const datas = action.value;
      return datas;
    default:
      return state;
  }
};
