import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
// material-ui
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
// cust component
import SearchInput from '../../components/CustComponents/SearchInput';
// css
import viewCss from '../../assets/scss/view.module.scss';

const EnhancedTableToolbar = (props) => {
  return (
    <Toolbar className={viewCss.toolBar}>
      <Typography className={viewCss.toolBarTitle} variant="h6" id="tableTitle" component="div">
        <FormattedMessage id='user_manager'/>
      </Typography>
      <Paper className={viewCss.toolBarFocusPaper}>
        <FormControl style={{ minWidth: "130px", margin: '0px 10px' }}>
          <InputLabel id="focus-orgnization-select-label" shrink={true}>
            <FormattedMessage id="orgnization"/>
          </InputLabel>
          
          <Select
            labelId="focus-orgnization-select-label"
            displayEmpty={true}
            style={{ width: '100%'}}
            id='focus-orgnization-select'
            disableUnderline
            label={props.intl.formatMessage({ id: 'orgnization' })}
            value={props.focusOrg}
            onChange={props.onFocusOrgChange}
          >
            <MenuItem value=''>All</MenuItem>
            {
              props.focusOrgOption.map((focusOrgStr) => {
                return <MenuItem value={focusOrgStr}>{focusOrgStr}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </Paper>
      <Tooltip title={props.intl.formatMessage({ id: 'search' })} style={{marginLeft:'1rem'}}>
        <SearchInput
          enable={true}
          style={{}}
          title={props.intl.formatMessage({ id: 'search_msg' })}
          placeholder={props.intl.formatMessage({ id: 'search_msg' })}
          value={props.onFilterValue}
          onChange={props.onFilterChange}
        />
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  onFilterValue: PropTypes.string.isRequired,
  focusOrg: PropTypes.string.isRequired,
  onFocusOrgChange: PropTypes.string.isRequired,
  focusOrgOption: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default injectIntl(EnhancedTableToolbar);
