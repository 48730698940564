import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { connect } from 'react-redux';

import VisibilityIcon from '@mui/icons-material/Visibility';
import CreateIcon from '@mui/icons-material/Create';
import RefreshIcon from '@mui/icons-material/Refresh';

import { EnhancedTableHead } from '../../components/TableComponent';
import {
  setAuditDatas,
  setLoading,
  setAuditRowsFilterInfo,
  setAuditRowsOrderInfo,
  setDataInitStatus
} from '../../actions'; // redux action
import {
  generateMonthTags,
  getComparator,
  stableSort,
  duplicateCheck,
  dataValidation
} from './auditUtil';
import { InfiniteScrollMutiPickSelect } from '../../components/CustComponents/MutiPickSelect';
import ExcelReaderButton from './ExcelReaderButton';

import { httpRequest } from '../../lib';
import tableInfo from '../../configuration/auditRuleRowTableInfo.json';
import tableFilterInfo from '../../configuration/auditRuleRowFilterInfo.json';
import viewCss from '../../assets/scss/view.module.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';
// other component
import { confirmAlert } from 'react-confirm-alert';

import { filterEngine } from '../../components/FilterComponent/filterLib';

const FILTER_DISPLAY = 'flatten'; // 'accordion' | 'flatten' | 'dialog'

const FILTER_FORM = {};
const TABLE_PER_PAGE = -1; // -1 disable page

const AUDIT_ROWS_DATA_FORM_INDEX = 0;

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const INIT_MONTH_TAG_OPTIONS =  generateMonthTags(0, 24).map((tagStr) => {
  const labelStr = tagStr.replace(/-|_/g, '/');
  return { label: labelStr, value: tagStr };
});

const mutiUpdateAuditDataSync = async (duplicateJsons, accessToken) => {
  const results = [];
  let showError = false;
  for (let i = 0; i < duplicateJsons.length; i += 1) {
    const src = `/api/audit/row/${duplicateJsons[i].originJson.sys_id}`;
    const body = { data: duplicateJsons[i].updateJson };
    const result = await new Promise((resolve, reject) => {
      httpRequest('POST', src, body, accessToken, (err, pBody) => {
        if (err) {
          resolve({ status: false, message: `${duplicateJsons[i].originJson.invoice_number}: ${err.message}` });
        } else {
          resolve({ ...pBody, message: `${duplicateJsons[i].originJson.invoice_number}: ${pBody.message}` });  
        }
      });
    });
    if (!result.status) {
      showError = true;
    }
    results.push(result);
  }

  if (showError) {
    const msgArray = results.map((result) => {
      return result.message;
    });
    return {
      status: false, message: msgArray.join('\n')
    }
  }
  return { status: true, message: 'Success' };
};

const addAuditDataSync = async (addJsons, accessToken) => {
  const pResult = await new Promise((resolve, reject) => {
    httpRequest('PUT', '/api/audit/rows', { datas: addJsons }, accessToken, (err, pBody) => {
      if (err) {
        resolve({ status: false, message: err.message });
        return;
      }
      resolve(pBody);
    });
  });
  if (!pResult.status) {
    return pResult;
  }

  const gResult = await new Promise((resolve, reject) => {
    httpRequest('GET', '/api/audit/rows', {}, accessToken, (err, gBody) => {
      if (err) {
        resolve({ status: false, message: err.message });
        return;
      }
      resolve(gBody);
    });
  });
  return gResult;
};

const AuditRows = (props) => {
  const navigate = useNavigate();
  const [tableHeight, setTableHeight] = React.useState(70);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [rowIndex, setRowIndex] = useState(-1);
  const [rowsPerPage, setRowsPerPage] = React.useState(TABLE_PER_PAGE);
  const [rowsPage, setRowsPage] = React.useState(0);
  const [monthTagOptions, setMonthTagOptions] = React.useState(INIT_MONTH_TAG_OPTIONS);

  useEffect((reProps, event) => {
    if (props.dataInitStatus.sheetDataForm && !props.dataInitStatus.auditRows) {
      props.setLoading(true);
      httpRequest('get', '/api/audit/rows', {}, props.auth.accessToken, (err, body) => {
        props.setDataInitStatus({ auditRows: true });
        props.setLoading(false);
        if (err) {
          confirmAlert({
            title: 'Warning',
            message: err.message,
            closeOnClickOutside: false,
            buttons: [{ label: 'Confirm' }]
          });
          return;
        }
        props.setAuditDatas(body.datas);
      });
    }
  }, [props.dataInitStatus.sheetDataForm]);

  const handleRequestSort = (event, property) => {
    const { order, orderBy } = props.auditRowsOrderInfo;
    const isAsc = orderBy === property && order === 'asc';
    props.setAuditRowsOrderInfo({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property
    });
    setRowIndex(-1);
  };

  const onEditClick = (id) => (event) => {
    navigate(`/audit_datas/${id}`);
  };

  const onAddClick = (event) => {
    navigate('/audit_datas/new');
  };

  const onRefreshClick = (event) => {
    props.setLoading(true);
    httpRequest('get', '/api/audit/rows', {}, props.auth.accessToken, (err, body) => {
      props.setLoading(false);
      if (err) {
        confirmAlert({
          title: 'Warning',
          message: err.message,
          closeOnClickOutside: false,
          buttons: [{ label: 'Confirm' }]
        });
        return;
      }
      props.setAuditDatas(body.datas);
    });
  };
  const onExcelValidate = (dataForm, grid) => {
    const dataAttrs = props.auditSheetDataForm[AUDIT_ROWS_DATA_FORM_INDEX].attributes.filter((arrInfo) => {
      return !arrInfo.field_key.startsWith('sys_');
    });
    const rowsJson = grid.filter((gridRow, gridRowIndex) => {
      const isExistRow = gridRow.some((gridCell) => {
        return !gridCell.readOnly && Boolean(gridCell.value);
      });
      return (gridRowIndex > 0) && isExistRow;
    }).map((gridRow, gridRowIndex) => {
      const jsonData = {};
      dataAttrs.map((attInfo, attIndex) => {
        jsonData[attInfo.field_key] = gridRow[attIndex + 1].value;
      });
      return jsonData;
    });

    for (let i = 0; i < rowsJson.length; i += 1) {
      // validate in here.
      const validateResult = dataValidation(true, rowsJson[i], dataAttrs);
      if (!validateResult.status) {
        confirmAlert({
          title: 'Warning',
          message: `Row ${i + 1}: ${props.intl.formatMessage({ id: validateResult.messageId })}`,
          closeOnClickOutside: false,
          buttons: [{ label: 'Confirm' }]
        });
        return false;
      }
    }
    return true;
  };

  const onExcelSubmit = (dataForm, grid) => {
    const dataAttrs = props.auditSheetDataForm[AUDIT_ROWS_DATA_FORM_INDEX].attributes.filter((arrInfo) => {
      return !arrInfo.field_key.startsWith('sys_');
    });
    const rowsJson = grid.filter((gridRow, gridRowIndex) => {
      const isExistRow = gridRow.some((gridCell) => {
        return !gridCell.readOnly && Boolean(gridCell.value);
      });
      return (gridRowIndex > 0) && isExistRow;
    }).map((gridRow, gridRowIndex) => {
      const jsonData = {};
      dataAttrs.map((attInfo, attIndex) => {
        jsonData[attInfo.field_key] = gridRow[attIndex + 1].value;
      });
      return jsonData;
    });
    const checkResult = duplicateCheck(props.auditDatas, rowsJson);
    const reqOptions = { datas: checkResult.newJsons };

    if (checkResult.duplicateJsons.length > 0) {
      const duplicateInvoiceStr = checkResult.duplicateJsons.map((dJson) => {
        return dJson.originJson.invoice_number;
      }).join(', ');

      confirmAlert({
        title: 'Warning',
        message: `${checkResult.duplicateJsons.length} invoice numbers already exist, please select operation\n${duplicateInvoiceStr}`,
        closeOnClickOutside: false,
        buttons: [
          { label: 'Update' , onClick: () => {
            props.setLoading(true);
            mutiUpdateAuditDataSync(checkResult.duplicateJsons, props.auth.accessToken).then((muResult) => {
              if (!muResult.status || checkResult.newJsons.length === 0) {
                props.setLoading(false);
                confirmAlert({
                  title: muResult.status ? 'Success' : 'Warn',
                  message: muResult.message,
                  closeOnClickOutside: false,
                  buttons: [{ label: 'Confirm' }]
                });
                return;
              }
              addAuditDataSync(checkResult.newJsons, props.auth.accessToken).then((aResult) => {
                props.setLoading(false);
                confirmAlert({
                  title: aResult.status ? 'Success' : 'Warn',
                  message: aResult.message,
                  closeOnClickOutside: false,
                  buttons: [{ label: 'Confirm' }]
                });
                if (aResult.status) {
                  props.setAuditDatas(aResult.datas);
                }
              }).catch((aErr) => {
                props.setLoading(false);
                // show error
                confirmAlert({
                  title: 'Warning',
                  message: aErr.message,
                  closeOnClickOutside: false,
                  buttons: [{ label: 'Confirm' }]
                });
              });
            }).catch((muErr) => {
              props.setLoading(false);
              // show error
              confirmAlert({
                title: 'Warning',
                message: muErr.message,
                closeOnClickOutside: false,
                buttons: [{ label: 'Confirm' }]
              });
            });
          } },
          { label: 'Skip', onClick: () => {
            if (checkResult.newJsons.length > 0) {
              props.setLoading(true);
              addAuditDataSync(checkResult.newJsons, props.auth.accessToken).then((aResult) => {
                props.setLoading(false);
                confirmAlert({
                  title: aResult.status ? 'Success' : 'Warn',
                  message: aResult.message,
                  closeOnClickOutside: false,
                  buttons: [{ label: 'Confirm' }]
                });
                if (aResult.status) {
                  props.setAuditDatas(aResult.datas);
                }
              }).catch((aErr) => {
                props.setLoading(false);
                // show error
                confirmAlert({
                  title: 'Warning',
                  message: aErr.message,
                  closeOnClickOutside: false,
                  buttons: [{ label: 'Confirm' }]
                });
              });
            }
          } }
        ]
      });
    } else if (checkResult.newJsons.length > 0) {
      addAuditDataSync(checkResult.newJsons, props.auth.accessToken).then((aResult) => {
        props.setLoading(false);
        confirmAlert({
          title: aResult.status ? 'Success' : 'Warn',
          message: aResult.message,
          closeOnClickOutside: false,
          buttons: [{ label: 'Confirm' }]
        });
        if (aResult.status) {
          props.setAuditDatas(aResult.datas);
        }
      }).catch((aErr) => {
        props.setLoading(false);
        // show error
        confirmAlert({
          title: 'Warning',
          message: aErr.message,
          closeOnClickOutside: false,
          buttons: [{ label: 'Confirm' }]
        });
      });
    }
  };
 
  const displayHeaders =  tableInfo.headers.filter((tableHeader) => {
    return (tableHeader.role.length === 0) || tableHeader.role.includes(props.account.role);
  });
  const tableContainerOffset = (rowsPerPage > 0) ? tableHeight + 166 : tableHeight + 105;
  const rows = props.auditSheetDataForm[AUDIT_ROWS_DATA_FORM_INDEX] ? filterEngine(
    props.auditSheetDataForm,
    stableSort(props.auditDatas, getComparator(props.auditRowsOrderInfo.order, props.auditRowsOrderInfo.orderBy)), // props.auditDatas,
    tableFilterInfo,
    props.auditRowsFilterInfo
  ) : props.auditDatas;

  const displayRows = (rowsPerPage > 0) ? rows.slice(rowsPage * rowsPerPage, rowsPage * rowsPerPage + rowsPerPage) : rows;
  const { sheet_id_key: sheetIdKey } = props.auditSheetDataForm[AUDIT_ROWS_DATA_FORM_INDEX] || {};
  return (
    <>
      <div className={viewCss.Audit}>
        <Paper className={viewCss.AuditPaper}>
          <div style={{ margin: '0px 0px 25px 0px', height: `${tableHeight}px`}}>
            <h1 style={{ float: 'left', margin: '10px 20px' }}>
              <FormattedMessage id={'audit'} />
            </h1>

            <TextField
              label={props.intl.formatMessage({ id: 'search' })}
              id={'field-search'}
              value={props.auditRowsFilterInfo.greedy}
              size="small"
              onChange={(event) => {
                props.setAuditRowsFilterInfo({ ...props.auditRowsFilterInfo, greedy: event.target.value });
              }}
            />
            <InfiniteScrollMutiPickSelect
              enable={true}
              style={{ minWidth: '150px' }}
              title={props.intl.formatMessage({ id: 'pick_tags' })}
              value={props.auditRowsFilterInfo.dateTags}
              options={monthTagOptions}
              onChange={(event) => {
                props.setAuditRowsFilterInfo({ ...props.auditRowsFilterInfo, dateTags: event.target.value });
              }}
              onScrollBottom={(event) => {
                const mtOptions = generateMonthTags(monthTagOptions.length, 12).map((tagStr) => {
                  const labelStr = tagStr.replace(/-|_/g, '/');
                  return { label: labelStr, value: tagStr };
                });
                setMonthTagOptions(monthTagOptions.concat(mtOptions));
              }}
            />
            {/* // TODO: cust date range tags picker. */}
            <Tooltip title={props.intl.formatMessage({ id: 'add' })}>
              <IconButton onClick={onAddClick}>
                <CreateIcon fontSize='large' color='primary'/>
              </IconButton>
            </Tooltip>
            <Tooltip title={props.intl.formatMessage({ id: 'refresh' })}>
              <IconButton onClick={onRefreshClick}>
                <RefreshIcon fontSize='large' color='primary'/>
              </IconButton>
            </Tooltip>
            <Tooltip title={props.intl.formatMessage({ id: 'import' })}>
              <ExcelReaderButton
                onSubmit={onExcelSubmit}
                formInfos={props.auditSheetDataForm}
                onValidate={onExcelValidate}
              />
            </Tooltip>
          </div>
          <TableContainer style={{ height: `calc(100vh - ${tableContainerOffset}px)` }}>
            <Table
              stickyHeader
              className={viewCss.AuditTable}
              aria-labelledby="tableTitle"
              size={'small'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                onRequestSort={handleRequestSort}
                heads={displayHeaders}
                order={props.auditRowsOrderInfo.order}
                orderBy={props.auditRowsOrderInfo.orderBy}
              />
              <TableBody>
                {
                  displayRows.map((row, index) => {
                    return (
                      <TableRow hover role='checkbox' tabIndex={-1} key={`${index}`}>
                        {
                          displayHeaders.map((headCell, headCellIndex) => {
                            if (headCell.type === 'view') {
                              return (
                                <TableCell padding="checkbox" key={`table_cell_${index}_${headCellIndex}`}>
                                  <IconButton
                                    aria-label="view"
                                    component={CustomRouterLink}
                                    to={`/audit_datas/${row[sheetIdKey]}`}
                                  >
                                    <VisibilityIcon style={{ color:'#dadada' }}/>
                                  </IconButton>
                                </TableCell>
                              );
                            }
                            // ignore empty value
                            if (headCell.type === 'date' && row[headCell.id]) {
                              return (
                                <TableCell align="left" key={`table_cell_${index}_${headCellIndex}`}>
                                  <FormattedDate value={row[headCell.id]} />
                                </TableCell>
                              );
                            }
                            return (
                              <TableCell align="left" padding="none" key={`table_cell_${index}_${headCellIndex}`}>
                                {row[headCell.id]}
                              </TableCell>
                            );
                          })
                        }
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const { auth, dealList, loading, account, notifyRule, orgMember, authInfo, auditSheetDataForm, auditRowsFilterInfo, auditDatas, auditRowsOrderInfo, dataInitStatus } = state;
  return { auth, dealList, loading, account, notifyRule, orgMember, authInfo, auditSheetDataForm, auditRowsFilterInfo, auditDatas, auditRowsOrderInfo, dataInitStatus };
};

const setStatusToProps = {
  setAuditDatas,
  setLoading,
  setAuditRowsFilterInfo,
  setAuditRowsOrderInfo,
  setDataInitStatus
};

export default connect(mapStateToProps, setStatusToProps)(withAuthenticationRequired(injectIntl(AuditRows)));
