import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

const FormDialog = ({
  fullScreen, open, maxWidth, handleDelete, handleClose, handleSave, title, children, deleteText, closeText, saveText, progress, PaperProps
}) => {
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      fullWidth={Boolean(maxWidth)}
      maxWidth={maxWidth || 'sm'}
      PaperProps={PaperProps}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent dividers>
        {progress && <LinearProgress />}
        {children}
      </DialogContent>
      <DialogActions>
        {
          deleteText && <>
            <Button onClick={handleDelete} color="error" disabled={progress}>
              {deleteText}
            </Button>
            <div style={{ flex: '1 0 0' }} />
          </>
        }
        {
          closeText && <>
            <Button onClick={handleClose} color="primary" disabled={progress}>
              {closeText}
            </Button>
          </>
        }
        <Button onClick={handleSave} color="primary" disabled={progress}>
          {saveText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FormDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  saveText: PropTypes.string.isRequired,
  closeText: PropTypes.string,
  deleteText: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  progress: PropTypes.bool
};

export default FormDialog;
