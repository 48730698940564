import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Sidebar, Topbar, Footer } from './components';

import layoutCss from '../../assets/scss/layout.module.scss';

const Main = props => {
  const { children } = props;

  const isDesktop = false;
  // const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
  //   defaultMatches: true
  // });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    // <div
    //   className={clsx({
    //     [layoutCss.main]: true,
    //     [layoutCss.MainShiftContent]: isDesktop
    //   })}
    // >
    <div id='948777' className={layoutCss.Main}>
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <main className={layoutCss.MainContent}>
        {children}
        <Footer />
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
