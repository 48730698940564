import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, injectIntl } from 'react-intl';
import { NavLink as RouterLink } from 'react-router-dom';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import VisibilityIcon from '@mui/icons-material/Visibility';

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const TableBodyRow = (props) => {
  return (
    <TableBody>
      {
        props.rows.map((row, rowIndex) => {
          return (
            <TableRow hover role='checkbox' tabIndex={-1} key={`${rowIndex}`}>
              {
                props.headers.map((headCell, headCellIndex) => {
                  const cellKeyStr = `table_cell_${rowIndex}_${headCellIndex}`;
                  if (headCell.type === 'view') {
                    const toStr = props.viewSrcFunc(row, rowIndex, headCell, headCellIndex);
                    return (
                      <TableCell padding="checkbox" key={cellKeyStr}>
                        <IconButton aria-label="view" component={CustomRouterLink} to={toStr}>
                          <VisibilityIcon style={{ color:'#dadada' }}/>
                        </IconButton>
                      </TableCell>
                    );
                  }
                  // ignore empty value
                  if (headCell.type === 'date' && row[headCell.id]) {
                    return (
                      <TableCell align="left" key={cellKeyStr}>
                        <FormattedDate value={row[headCell.id]} />
                      </TableCell>
                    );
                  }
                  if (headCell.type === 'cust_value' && props.custValueFunc) {                    
                    return (
                      <TableCell align="left" padding="none" key={cellKeyStr}>
                        {props.custValueFunc(row, rowIndex, headCell, headCellIndex)}
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell align="left" padding="none" key={cellKeyStr}>
                      {row[headCell.id]}
                    </TableCell>
                  );
                })
              }
            </TableRow>
          );
        })
      }
    </TableBody>
  );
};

TableBodyRow.propTypes = {
  rows: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  viewSrcFunc: PropTypes.func.isRequired, // (row, rowIndex, headCell, headCellIndex) => string
  custValueFunc: PropTypes.func, // (row, rowIndex, headCell, headCellIndex) => string/any
};

export default injectIntl(TableBodyRow);
