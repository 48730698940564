import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography, Link } from '@mui/material';

const Footer = props => {
  const { className, ...rest } = props;

  return null;
  // return (
  //   <div
  //     {...rest}
  //     className={clsx(classes.root, className)}
  //   >
  //     <Typography variant="body1">
  //       &copy;{' '}
  //       <Link
  //         component="a"
  //         href="https://devias.io/"
  //         target="_blank"
  //       >
  //         Devias IO
  //       </Link>
  //       . 2019
  //     </Typography>
  //     <Typography variant="caption">
  //       Created with love for the environment. By designers and developers who
  //       love to work together in offices!
  //     </Typography>
  //   </div>
  // );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
