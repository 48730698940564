import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, IconButton } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import MenuIcon from '@mui/icons-material/Menu';
import envConfig from '../../../../configuration/env.json';

import layoutCss from '../../../../assets/scss/layout.module.scss';

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  // const { logout } = useAuth0();
  // const [notifications] = useState([]);

  return (
    <AppBar
      {...rest}
      className={clsx(layoutCss.MainToolbar, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <h2 style={{ color: '#ffffff'}}>
            {envConfig.name}
          </h2>
        </RouterLink>
        <div className={layoutCss.MainToolbarFlexGrow} />
        <IconButton
          color="inherit"
          onClick={onSidebarOpen}
        >
          <MenuIcon />
        </IconButton>
        {/*</Hidden>*/}
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
