import React from 'react';
import PropTypes from 'prop-types';
// material-ui
import { LocalizationProvider, DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

/* ***********
  <DatePicker
    enable={true}
    label={''}
    value={null}
    onDateChange={(event) => {
      const value = event.target.value;
    }}
  />
*********** */

const DatePicker = (props) => {
  if (!props.enable) {
    return null;
  }
  const value = props.value ? new Date(props.value) : null;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        sx={{ width: '100%' }}
        maxDate={new Date('9999-12-31')}
        label={props.label}
        value={value}
        format="yyyy-MM-dd"
        onChange={props.onChange}
        slotProps={{ actionBar: { actions: ['clear'] } }}
        PopoverProps={{
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          transformOrigin: { horizontal: "center", vertical: "bottom" }
        }}
      />
    </LocalizationProvider>
  );
};

DatePicker.propTypes = {
  enable: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DatePicker;
export {
  DatePicker
};
