import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Avatar, Typography } from '@mui/material';
import { connect } from 'react-redux';

import layoutCss from '../../../../../../assets/scss/layout.module.scss';

const Profile = props => {
  const { className, account, auth, ...rest } = props;

  const user = {
    name: account.name,
    avatar: auth.picture,
    bio: account.email
  };

  return (
    <div
      {...rest}
      className={clsx(layoutCss.MainSidebarProfile, className)}
    >
      <Avatar
        alt="Person"
        className={layoutCss.MainSidebarProfileAvatar}
        component={RouterLink}
        src={user.avatar}
        to="/account"
      />
      <h4 className={layoutCss.MainSidebarProfileName}>
        {user.name}
      </h4>
      <p className={layoutCss.MainSidebarProfileMail}>
        {user.bio}
      </p>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => {
  const { auth, account } = state;
  return { auth, account };
};

export default connect(mapStateToProps)(Profile);
