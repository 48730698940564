import React from 'react';
import PropTypes from 'prop-types';
// material-ui
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

/*
  <PickSelect
    enable={true}
    style={{}}
    title={''}
    value={''}
    options={[]} // Array<{ label: string, value: any }>
    onChange={(event) => {
      const value = event.target.value;
    }}
  />
*/

const PickSelect = (props) => {
  if (!props.enable) {
    return null;
  }
  return (
    <FormControl style={props.style}>
      <InputLabel id="update-range-mutiple-chip-label">{props.title}</InputLabel>
      <Select
        labelId="update-range-mutiple-chip-label"
        id="update-range-mutiple-chip"
        value={props.value}
        onChange={props.onChange}
        input={<Input id="update-range-multiple-chip" />}
        renderValue={selected => {
          const selectedOption = props.options.find((opt) => {
            return selected === opt.value;
          });
          return(
            <div style={{ display: 'flex', flexWrap: 'wrap' }} >
              <Chip style={{ width: '100%' }} label={selectedOption.label} />
            </div>
          );
        }}
        MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250, } } }}
      >
        {props.options.map((sObj) => (
          <MenuItem value={sObj.value}>{sObj.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

PickSelect.propTypes = {
  enable: PropTypes.bool,
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PickSelect;

export {
  PickSelect
};
