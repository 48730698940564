import { CONSTANTS } from "./constants";

const AUDIT_ORDER_INIT_INFO = {
  successOrder: '', // asc, desc
  successOrderBy: '',
  warnOrder: '', // asc, desc
  warnOrderBy: '',
  unmatchedOrder: '', // asc, desc
  unmatchedOrderBy: ''
};

export default (state = AUDIT_ORDER_INIT_INFO, action) => {
  switch (action.type) {
    case CONSTANTS.SET_AUDIT_ORDER_INFO:
      const {
        successOrder = state.successOrder,
        successOrderBy = state.successOrderBy,
        warnOrder = state.warnOrder,
        warnOrderBy = state.warnOrderBy,
        unmatchedOrder = state.unmatchedOrder,
        unmatchedOrderBy = state.unmatchedOrderBy,
      } = action.value;

      return {
        successOrder,
        successOrderBy,
        warnOrder,
        warnOrderBy,
        unmatchedOrder,
        unmatchedOrderBy
      };
    default:
      return state;
  }
};
