import React from 'react';
import PropTypes from 'prop-types';

import { Topbar } from './components';
import layoutCss from '../../assets/scss/layout.module.scss';

const Minimal = props => {
  const { children } = props;

  return (
    <div className={layoutCss.Minimal}>
      <Topbar />
      <main className={layoutCss.MinimalContent}>{children}</main>
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Minimal;
