import React from "react";
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { confirmAlert } from 'react-confirm-alert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';

import ReqViewer from './ReqViewer';

import { httpRequest } from '../../lib';

import 'react-confirm-alert/src/react-confirm-alert.css';
import viewCss from '../../assets/scss/view.module.scss';

const VIEW_VALUE_STYLE = {
  padding: '0px 10px',
  color: '#23a455'
};

const RETRY_OPERATE_TYPES = ['update_row', 'add_rows'];

const renderQueryParams = (jsonQuery) => {
  if (!jsonQuery) {
    return '';
  }
  const keys = Object.keys(jsonQuery);
  if (keys.length === 0) {
    return '';
  }
  const queryParamStr = keys.map((key) => {
    return `${key}=${encodeURI(jsonQuery[key])}`;
  }).join('&');
  return `?${queryParamStr}`;
};

const renderRetryUrl = (params, query) => {
  const { dataForm, case_id: caseID } = params;
  if (caseID) {
    return `${dataForm}/${caseID}${renderQueryParams(query)}`;
  }
  return `${dataForm}${renderQueryParams(query)}`;
};

const TaskLogDialog = (props) => {
  const onCloseTaskLogDialog = (event) => {
    props.onClose();
  };

  const onRetryTask = (event) => {
    if (RETRY_OPERATE_TYPES.includes(props.taskLog.operate)) {
      const { params, query, body } = props.taskLog.requestInfo;
      const url = `/api/dealtrack/rows/${renderRetryUrl(params, query)}`;
      // reload
      props.onLoading(true);
      httpRequest(props.taskLog.method, url, body, props.auth.accessToken, (statusCode, body) => {
        props.onLoading(false);
        props.onClose();
        confirmAlert({
          title: 'Message',
          message: body.message,
          closeOnClickOutside: false,
          buttons: [{ label: 'Confirm' }]
        });
      });
    } else {
      props.onClose();
      confirmAlert({
        title: 'Warning',
        message: props.intl.formatMessage({ id: 'not_support_retry_type' }),
        closeOnClickOutside: false,
        buttons: [{ label: 'Confirm' }]
      });
    }
  };

  return (
    <Dialog
      maxWidth='md'
      open={props.open}
      onClose={onCloseTaskLogDialog}
      aria-labelledby="task-log-dialog-title"
      aria-describedby="task-log-dialog-description"
    >
      <DialogTitle style={{ minWidth: '600px'}} id="task-log-dialog-title">
        <FormattedMessage id="task_log_title" />
      </DialogTitle>
      <DialogContent dividers>
        {
          props.taskLog &&
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td><FormattedMessage id="operate" /></td>
                <td style={VIEW_VALUE_STYLE}>
                  <FormattedMessage id={`operate_${props.taskLog.operate || 'unknown'}`} />
                </td>
              </tr>
              {
                props.taskLog.resultData ?
                <>
                  <tr>
                    <td><FormattedMessage id="task_log_message" /></td>
                    <td style={VIEW_VALUE_STYLE}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={viewCss.TaskLogDialogHeading}>{props.taskLog.resultData.message}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography style={{ lineBreak: 'anywhere'}}>
                            <ReqViewer requestInfo={props.taskLog.resultData} />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </td>
                  </tr>
                </> : <>
                  <tr>
                    <td><FormattedMessage id="task_log_message" /></td>
                    <td style={VIEW_VALUE_STYLE}><FormattedMessage id="processing" /></td>
                  </tr>
                </>
              }
              <tr>
                <td><FormattedMessage id="owner" /></td>
                <td style={VIEW_VALUE_STYLE}>{props.taskLog.userName || 'N/A'}</td>
              </tr>
              <tr>
                <td><FormattedMessage id="req_time" /></td>
                <td style={VIEW_VALUE_STYLE}>{props.taskLog.reqTime}</td>
              </tr>
              <tr>
                <td><FormattedMessage id="end_time" /></td>
                <td style={VIEW_VALUE_STYLE}>{props.taskLog.endTime || 'N/A'}</td>
              </tr>
              {
                props.taskLog.resultData &&
                !props.taskLog.resultData.status &&
                RETRY_OPERATE_TYPES.includes(props.taskLog.operate) &&
                <tr>
                  <td><FormattedMessage id="retry" /></td>
                  <td style={{ padding: '0px 10px;' }}>
                    <IconButton onClick={onRetryTask} variant="contained" size='small'>
                      <RefreshIcon />
                    </IconButton>
                  </td>
                </tr>
              }
              <tr>
                <td><FormattedMessage id="task_log_req_data" /></td>
                <td>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={viewCss.TaskLogDialogHeading}><FormattedMessage id="detail" /></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={{ lineBreak: 'anywhere'}}>
                        <ReqViewer requestInfo={props.taskLog.requestInfo} />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </td>
              </tr>
            </tbody>
          </table>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseTaskLogDialog} variant="contained">
          <FormattedMessage id="close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TaskLogDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  taskLog: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  onLoading: PropTypes.func.isRequired
};

export default injectIntl(TaskLogDialog);
