import { CONSTANTS } from "./constants";

const INIT_FILTER_INFO = {};

export default (state = INIT_FILTER_INFO, action) => {
  switch (action.type) {
    case CONSTANTS.SET_FILTER_INFO :
      return action.value;
    default:
      return state;
  }
}
