import React from 'react';
import PropTypes from 'prop-types';
// material-ui
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

/*
  <MutiPickSelect
    enable={true}
    style={{}}
    title={''}
    value={''}
    options={[]} // Array<{ label: string, value: any }>
    onChange={(event) => {
      const value = event.target.value;
    }}
  />
*/

const MutiPickSelect = (props) => {
  if (!props.enable) {
    return null;
  }
  return (
    <FormControl style={props.style}>
      <InputLabel id="status-mutiple-chip-label">{props.title}</InputLabel>
      <Select
        labelId="status-mutiple-chip-label"
        id="status-mutiple-chip"
        multiple
        value={props.value}
        onChange={props.onChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => {
          const selectedOptions = props.options.filter((opt) => {
            return selected.includes(opt.value);
          });
          return(
            <div style={{ display: 'flex', flexWrap: 'wrap' }} >
              {selectedOptions.map((opt) => ( <Chip label={opt.label}/> ))}
            </div>
          );
        }}
        MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } } }}
      >
        {props.options.map((sObj) => (
          <MenuItem value={sObj.value}>{sObj.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

MutiPickSelect.propTypes = {
  enable: PropTypes.bool,
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

/*
  <InfiniteScrollMutiPickSelect
    enable={true}
    style={{}}
    title={''}
    value={''}
    options={[]} // Array<{ label: string, value: any }>
    onChange={(event) => {
      const value = event.target.value;
    }}
  />
*/

const InfiniteScrollMutiPickSelect = (props) => {
  if (!props.enable) {
    return null;
  }
  const handleScroll = (event) => {
    const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    if (bottom) {
      props.onScrollBottom(event);
    }
  };
  return (
    <FormControl style={props.style}>
      <InputLabel id="status-mutiple-chip-label">{props.title}</InputLabel>
      <Select
        labelId="status-mutiple-chip-label"
        id="status-mutiple-chip"
        multiple
        value={props.value}
        onChange={props.onChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => {
          const selectedOptions = props.options.filter((opt) => {
            return selected.includes(opt.value);
          });
          return(
            <div style={{ display: 'flex', flexWrap: 'wrap' }} >
              {selectedOptions.map((opt) => ( <Chip label={opt.label}/> ))}
            </div>
          );
        }}
        MenuProps={{
          PaperProps: {
            onScroll: handleScroll,
            style: { maxHeight: 48 * 4.5 + 8, width: 250 }
          }
        }}
      >
        {props.options.map((sObj) => (
          <MenuItem value={sObj.value}>{sObj.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

InfiniteScrollMutiPickSelect.propTypes = {
  enable: PropTypes.bool,
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onScrollBottom: PropTypes.func.isRequired
};
export default MutiPickSelect;

export {
  MutiPickSelect,
  InfiniteScrollMutiPickSelect
};