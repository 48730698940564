import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { connect, Provider } from 'react-redux';
import { createStore } from 'redux'

import rootReducer from './reducer';

import * as serviceWorker from './serviceWorker';
import App from './App';
import en_US from './i18n/en_US';
import zh_TW from './i18n/zh_TW';
import zh_CN from './i18n/zh_CN';

import './index.css'

const store = createStore(rootReducer);

const lang = {
  'en': en_US,
  'en-US': en_US,
  'en-us': en_US,
  'en_US': en_US,
  'en_us': en_US,
  'zh-TW': zh_TW,
  'zh-tw': zh_TW,
  'zh_TW': zh_TW,
  'zh_tw': zh_TW,
  'zh-CN': zh_CN,
  'zh-cn': zh_CN,
  'zh_CN': zh_CN,
  'zh_cn': zh_CN
}

class Index extends React.Component {
  constructor(props) {
    super(props);
    const ckey = navigator.language;
    this.state = {
      chooseLanguage: lang[ckey] || lang.en
    }
  }
  componentDidUpdate(preProps) {
    if (this.props.account && this.props.account.language !== preProps.account.language) {
      this.setState({ chooseLanguage: lang[this.props.account.language] || lang.en });
    }
  }
  render() {
    return (
      <div style={{ height: '100%' }}>
        <IntlProvider locale={navigator.language} messages={this.state.chooseLanguage}>
          <App />
        </IntlProvider>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { account } = state;
  return { account };
};

const MyIndex = connect(mapStateToProps)(Index);

class IndexProvider extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <MyIndex/>
      </Provider>
    );
  }
}

ReactDOM.render(<IndexProvider />, document.getElementById('root'));

serviceWorker.unregister();
