import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid2 from '@mui/material/Unstable_Grid2';
import Tooltip from '@mui/material/Tooltip';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const GridInputSize = { md: 4, sm: 6, xs: 12 };

const FileUploader = (props) => {
  const inputRef = useRef(null);

  const handleUploadClick = () => {
    // 👇 We redirect the click event onto the hidden input element
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      props.onFileChange(null);
      return;
    }
    props.onFileChange(event.target.files[0]);
  };

  return (
    <Grid2 container spacing={2} style={{ ...props.style }}>
      <Grid2 item md={GridInputSize.md} sm={GridInputSize.sm} xs={GridInputSize.xs}>
        {/* 👇 Our custom button to select and upload a file */}
        {
          (props.btnStyle === 'icon') ? (
            <Tooltip title={props.intl.formatMessage({ id: 'upload' })}>
              <IconButton color='primary' size='large' onClick={handleUploadClick}>
                <CloudUploadIcon/>
              </IconButton>
            </Tooltip>
          ) : (
            <Button variant="outlined" onClick={handleUploadClick} style={{ width: '100%'}}>
              {props.fileName ? props.fileName : props.placeholder}
            </Button>
          )
        }
        {/* 👇 Notice the `display: hidden` on the input */}
        <input
          type="file"
          ref={inputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </Grid2>
    </Grid2>
  );
};

{/* <IconButton aria-label="view" onClick={onUploadFile}>
  <CloudUploadIcon style={{ color:'#dadada' }}/>
</IconButton> */}
FileUploader.propTypes = {
  fileName: PropTypes.string,
  placeholder: PropTypes.string,
  btnStyle: PropTypes.string,
  onFileChange: PropTypes.func.isRequired
};

export default FileUploader;

export {
  FileUploader
}
