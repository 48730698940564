import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// 先顯示的比重大，要放後面
const SORT_ORDER = [
  "query",
  "params",
  "message",
  "status"
];

const sortInfoFunc = (a, b) => {
  const aIndex = SORT_ORDER.indexOf(a);
  const bIndex = SORT_ORDER.indexOf(b);
  return bIndex - aIndex;
};

const ReqViewer = (props) => {
  const requestInfo = props.requestInfo || {};
  const requestInfoKeys = Object.keys(requestInfo);

  return (
    requestInfoKeys.sort(sortInfoFunc).map((key) => {
      return (
        <TextField
          label={capitalizeFirstLetter(key)}
          inputProps={{
            className: 'squeeze-text-field-area'
          }}
          fullWidth
          multiline
          disabled
          value={JSON.stringify(props.requestInfo[key], null, 2)}
        />
      );
    })
  );
};

ReqViewer.propTypes = {
  requestInfo: PropTypes.object
};

export default ReqViewer;