const MOBILE_SCREEN_STR = 'mobile';
const MEDIUM_SCREEN_STR = 'medium';
const LARGE_SCREEN_STR = 'large';

const SCREEN_SIZE_INFO_MAP = {
  [MOBILE_SCREEN_STR]: {
    streamline: true
  },
  [MEDIUM_SCREEN_STR]: {
    streamline: false
  },
  [LARGE_SCREEN_STR]: {
    streamline: false
  }
};

const formatDateStr = (obj) => {
  if (!obj) {
    return null;
  }
  const datemyDay = (typeof obj.getMonth === 'function') ? obj : new Date(obj);
  // return datemyDay;

  const yyyy = datemyDay.getFullYear();
  const mm = (datemyDay.getMonth() < 9) ? `0${datemyDay.getMonth() + 1}` : datemyDay.getMonth() + 1;
  const dd = (datemyDay.getDate() < 10) ? `0${datemyDay.getDate()}` : datemyDay.getDate();

  return `${yyyy}-${mm}-${dd}`;
};

const validateLookup = (value, lookup) => {
  if (Array.isArray(value)) {
    return value.every((v) => {
      return lookup.some((option) => {
        return option.value && option.value === v;
      });
    });
  }

  return lookup.some((option) => {
    return option.value && option.value === value;
  });
};

const validateEngine = (form, attributes, validateRules) => {
  const mapIndex = {};
  attributes.forEach((attr, index) => {
    mapIndex[attr.field_key] = index;
  });
  for (let i = 0; i < validateRules.length; i += 1) {
    const rule = validateRules[i];
    if (!rule.enable) {
      continue;
    }

    const attr = attributes[mapIndex[rule.field_key]];
    if (rule.field_type === 'lookup') {
      if (!validateLookup(form[rule.field_key], attr.lookup)) {
        return { status: false, message: rule.warn_msg, messageId: rule.warn_msg_id };
      }
    } else if (rule.field_type === 'not_zero') {
      if (form[rule.field_key] === 0 || form[rule.field_key] === '0') {
        return { status: false, message: rule.warn_msg, messageId: rule.warn_msg_id };
      }
    } else {
      return { status: false, message: 'Not support type', messageId: 'not_support_type' };
    }
  }
  return { status: true, message: 'Success', messageId: 'success'};
};

const renderHeader = (role, screen, tableInfo) => {
  const { streamline } = SCREEN_SIZE_INFO_MAP[screen];
  const roldCheckFunc = (cell) => {
    return (cell.role && cell.role.length > 0) ? cell.role.includes(role) : true;
  };
  if (streamline) {
    return tableInfo.streamlineHeaders.filter(roldCheckFunc);
  }
  return tableInfo.headers.filter(roldCheckFunc);
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export {
  formatDateStr,
  validateLookup,
  validateEngine,
  renderHeader,
  descendingComparator,
  getComparator,
  stableSort
};
