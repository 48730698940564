const SEARCH_STATUS = [
  { label: 'Waiting', value: 'Waiting', roles: ['owner', 'manager', 'admin'] },
  { label: 'Approved', value: 'Approved', roles: ['owner', 'manager', 'admin'] },
  { label: 'Open', value: 'Open', roles: ['owner', 'manager', 'admin'] },
  { label: 'Reject', value: 'Reject', roles: ['owner', 'manager', 'admin'] },
  { label: 'Expired', value: 'Expired', roles: ['owner', 'manager', 'admin'] },
  { label: 'Archive', value: 'Archive', roles: ['admin'] }
];

const UPDATE_TIME_RANGE = [
  { label: 'All', value: 0, range1: 0, range2: 9999, roles: ['owner', 'manager', 'admin'] },
  { label: '0 ~ 15', value: 1, range1: 0, range2: 15, roles: ['owner', 'manager', 'admin'] },
  { label: '0 ~ 30', value: 2, range1: 0, range2: 30, roles: ['owner', 'manager', 'admin'] },
  { label: '15 ~ 30', value: 3, range1: 15, range2: 30, roles: ['owner', 'manager', 'admin'] },
  { label: '15 ~ ∞', value: 4, range1: 15, range2: 9999, roles: ['owner', 'manager', 'admin'] },
  { label: '30 ~ ∞', value: 5, range1: 30, range2: 9999, roles: ['owner', 'manager', 'admin'] },
];

const PRODUCT_TYPE = [
  { label: 'New Business', value: 'New Business', roles: ['owner', 'manager', 'admin'] },
  { label: 'Existing Business', value: 'Existing Business', roles: ['owner', 'manager', 'admin'] }
];

const RUNNING_PROGRESS_OPTIONS = ['10%', '20%', '30%', '35%', '40%', '50%', '60%', '70%', '80%', '90%'];

const REGISTRATION_PROGRESS = [
  { label: 'All', value: '', roles: ['owner', 'manager', 'admin'] },
  { label: 'Running', value: 'running', roles: ['owner', 'manager', 'admin'] },
  { label: '1%', value: '1%', roles: ['owner', 'manager', 'admin'] },
  { label: '10%', value: '10%', roles: ['owner', 'manager', 'admin'] },
  { label: '20%', value: '20%', roles: ['owner', 'manager', 'admin'] },
  { label: '30%', value: '30%', roles: ['owner', 'manager', 'admin'] },
  { label: '35%', value: '35%', roles: ['owner', 'manager', 'admin'] },
  { label: '40%', value: '40%', roles: ['owner', 'manager', 'admin'] },
  { label: '50%', value: '50%', roles: ['owner', 'manager', 'admin'] },
  { label: '60%', value: '60%', roles: ['owner', 'manager', 'admin'] },
  { label: '70%', value: '70%', roles: ['owner', 'manager', 'admin'] },
  { label: '80%', value: '80%', roles: ['owner', 'manager', 'admin'] },
  { label: '90%', value: '90%', roles: ['owner', 'manager', 'admin'] },
  { label: '100%', value: '100%', roles: ['owner', 'manager', 'admin'] }
];

const SEARCH_EXCLUDE_KEY = ['sys_case_uid', 'owner_uid'];
const DR_SEARCH_EXCLUDE_STATUS = ['Waiting', 'Open', 'Reject'];

const dateOffset = (dateValue, hh = 0, mm = 0, ss = 0, ms = 0) => {
  const date = new Date(dateValue);
  date.setHours(hh);
  date.setMinutes(mm);
  date.setSeconds(ss);
  date.setMilliseconds(ms);
  return date;
};

const diffDays = (dateStr) => {
  const date = dateOffset(dateStr);
  const now = dateOffset(new Date());
  if (Number.isNaN(Number(date.getTime()))) {
    return NaN;
  }
  const timeDifference = now.getTime() - date.getTime();
  const differentDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
  return differentDays;
};

const checkOnDateRange = (myDate, range1, range2) => {
  // myDate 可為空，不需檢查
  // if (!myDate) {
  //   return false;
  // }
  // check range1 <= myDate <= range2
  const myDateValue = dateOffset(myDate);
  if (range1 && dateOffset(range1) > myDateValue) {
    return false;
  }
  if (range2 && dateOffset(range2) < myDateValue) {
    return false;
  }
  return true;
};


const greedyEngine = (attributes, row, greedyStr) => {
  if (!greedyStr) {
    return true;
  }
  const EXCLUDE_KEYS = ['sys_id', 'sys_file_name', 'sys_create_stamp', 'sys_update_stamp'];
  const FILTER_STR_TYPES = ['string', 'text', 'multiline', 'number'];
  const strs = greedyStr.trim().toLowerCase().split(' ').filter((str) => {
    return str.trim();
  });
  const result = strs.some((str) => {
    return attributes.some((fInfo) => {
      if (EXCLUDE_KEYS.includes(fInfo.field_key)) {
        return false;
      }
      if (fInfo.display !== undefined && !fInfo.display || !FILTER_STR_TYPES.includes(fInfo.field_type)) {
        return false;
      }
      if (typeof row[fInfo.field_key] === 'string') {
        return row[fInfo.field_key].toLowerCase().includes(str);
      }
      return false;
    });
  });
  return result;
};

const strictEngine = (attributes, row, strictStr) => {
  if (!strictStr) {
    return true;
  }
  const FILTER_STR_TYPES = ['string', 'text', 'multiline', 'number'];
  const strs = strictStr.trim().toLowerCase().split(' ').filter((str) => {
    return str.trim();
  });
  const result = strs.every((str) => {
    return attributes.some((fInfo) => {
      if (!fInfo.display || !FILTER_STR_TYPES.includes(fInfo.field_type)) {
        return false;
      }
      if (typeof row[fInfo.field_key] === 'string') {
        return row[fInfo.field_key].toLowerCase().includes(str);
      }
      return false;
    });
  });
  return result;
};

const strEngine = (formValue, testStr, conditionStr) => {
  if (!testStr) {
    return true;
  }
  const conditions = conditionStr.split('!');
  const negative = conditions.length > 1;
  const condition = conditions.slice(-1)[0];

  if (condition === 'equal') {
    return negative ? formValue !== testStr : formValue === testStr;
  }
  if (condition === 'contain') {
    return negative ? !formValue.includes(testStr) : formValue.includes(testStr);
  }
  if (condition === 'start') {
    return negative ? !formValue.startsWith(testStr) : formValue.startsWith(testStr);
  }
  if (condition === 'end') {
    return negative ? !formValue.endsWith(testStr) : formValue.endsWith(testStr);
  }
  // condition not found
  return false;
};

const includesEngine = (formValue, testValues, conditionStr) => {
  if (!testValues || Array.isArray(testValues) && testValues.length === 0) {
    return true;
  }

  const conditions = conditionStr.split('!');
  const negative = conditions.length > 1;
  const condition = conditions.slice(-1)[0];

  if (typeof formValue === 'string' && condition === 'contain') {
    return negative ? !testValues.includes(formValue) : testValues.includes(formValue);
  }
  if (Array.isArray(formValue) && condition === 'contain') {
    return negative ? !formValue.some((v) => {
      return testValues.includes(v);
    }) : formValue.some((v) => {
      return testValues.includes(v);
    });
  }
  // condition not found
  return false;
};

const formatDate = (dateValue, hh = 0, mm = 0, ss = 0, ms = 0) => {
  dateValue.setHours(hh);
  dateValue.setMinutes(mm);
  dateValue.setSeconds(ss);
  dateValue.setMilliseconds(ms);
  return dateValue;
};

const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
}

const dateEngine = (formValue, testValue, conditionStr) => {
  const fv = new Date(formValue);
  const tv = new Date(testValue);
  if (!isValidDate(fv)) {
    return false;
  }
  if (!isValidDate(tv)) {
    return true;
  }
  const formDate = formatDate(fv);
  const testDate = formatDate(tv);
  const conditions = conditionStr.split('!');
  const negative = conditions.length > 1;
  const condition = conditions.slice(-1)[0];

  if (condition === 'equal') {
    return negative ? formDate !== testDate : formDate === testDate;
  }
  if (condition === 'more') {
    return negative ? formDate <= testDate : formDate > testDate;
  }
  if (condition === 'less') {
    return negative ? formDate >= testDate : formDate < testDate;
  }
  // condition not found
  return false;
};

const dateRangeEngine = (formValue, testValue1, testValue2, conditionStr) => {
  const fv = new Date(formValue || 'invalid'); // new Date(null) is 1970-01-01T00:00:00.000Z
  const tv1 = new Date(testValue1 || 'invalid');
  const tv2 = new Date(testValue2 || 'invalid');
  if (!isValidDate(fv)) {
    return !isValidDate(tv1) && !isValidDate(tv2);
  }
  const conditions = conditionStr.split('!');
  const negative = conditions.length > 1;
  const condition = conditions.slice(-1)[0];

  if (condition === 'range') {
    let lefyResult = false;
    let rightResult = false;
    if (isValidDate(tv1)) {
      const testDate1 = formatDate(tv1);
      const formDate = formatDate(fv);
      lefyResult = formDate >= testDate1;
    } else {
      lefyResult = true;
    }

    if (isValidDate(tv2)) {
      const testDate2 = formatDate(tv2);
      const formDate = formatDate(fv);
      rightResult = formDate <= testDate2;
    } else {
      rightResult = true;
    }
    return negative ? !(lefyResult && rightResult) : (lefyResult && rightResult);
  }
  // condition not found
  return false;
};

const filterEngine = (sheetFormInfos, rows, filterInfo, filterForm) => {
  const formInfoMap = {};
  sheetFormInfos.forEach((fInfo) => {
    formInfoMap[fInfo.name] = fInfo.attributes;
  });
  const result = rows.filter((row) => {
    const sFormIndex = sheetFormInfos.findIndex((fInfo) => {
      return fInfo.name === row.dataForm;
    });
    const sheetFormIndex = (sFormIndex === -1) ? 0 : sFormIndex;

    return filterInfo.every((fInfo) => {
      if (!fInfo.enable) {
        return true;
      }
      if (fInfo.type === 'string' && fInfo.condition === 'greedy') {
        return greedyEngine(sheetFormInfos[sheetFormIndex].attributes, row, filterForm[fInfo.keys[0]]);
      }
      if (fInfo.type === 'string' && fInfo.condition === 'strict') {
        return strictEngine(sheetFormInfos[sheetFormIndex].attributes, row, filterForm[fInfo.keys[0]]);
      }
      if (fInfo.type === 'muti_select') {
        return includesEngine(row[fInfo.formKey], filterForm[fInfo.keys[0]], fInfo.condition);
      }
      if (fInfo.type === 'date_range') {
        return dateRangeEngine(row[fInfo.formKey], filterForm[fInfo.keys[0]], filterForm[fInfo.keys[1]], fInfo.condition);
      }
      return strEngine(row[fInfo.formKey], filterForm[fInfo.keys[0]], fInfo.condition);
    });
  });
  return result;  
};

const optionRoleFilter = (role, options) => {
  const currentRole = role || 'owner';
  return options.filter((opt) => {
    return opt.roles.includes(currentRole);
  });
};

const FILTER_CONSTANTS = {
  SEARCH_STATUS,
  UPDATE_TIME_RANGE,
  PRODUCT_TYPE,
  REGISTRATION_PROGRESS,
  SEARCH_EXCLUDE_KEY,
  DR_SEARCH_EXCLUDE_STATUS
};

export {
  FILTER_CONSTANTS,
  filterEngine,
  optionRoleFilter
};
